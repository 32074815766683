import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M9.07 6.67a2.18 2.18 0 013.1 0l2.53 2.54c.85.85.85 2.23 0 3.08a.68.68 0 000 .97l4.05 4.06c.27.27.7.27.97 0M9.07 6.67l-.6.6a5.06 5.06 0 00-.68 6.31 39.6 39.6 0 0010.74 10.7 5.05 5.05 0 006.21-.74l.6-.6c.86-.85.86-2.23 0-3.09l-2.53-2.53a2.19 2.19 0 00-3.09 0m-.52 5.61l.06.04c1.4.9 3.24.69 4.42-.49l.6-.6a.69.69 0 000-.97m0 0l-2.53-2.53a.68.68 0 00-.97 0c-.85.85-2.23.85-3.09 0l-4.05-4.06a2.18 2.18 0 010-3.09.68.68 0 000-.96L11.1 7.73a.68.68 0 00-.97 0l-.6.6a3.56 3.56 0 00-.45 4.48A38.1 38.1 0 0019.2 22.93" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M8.95 1.93A15.75 15.75 0 0128.6 25.47a.75.75 0 11-1.2-.9 14.25 14.25 0 10-11.4 5.7c2.86 0 4.76-.7 5.76-1.73.95-.98 1.24-2.43.5-4.38a.75.75 0 011.4-.54c.89 2.33.64 4.45-.82 5.96-1.41 1.46-3.8 2.19-6.84 2.19v-.75.75A15.75 15.75 0 018.95 1.93z" clipRule="evenodd"/>
</svg>;

export default Index;
