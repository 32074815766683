import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 80" xmlSpace="preserve">
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path
          d="M4.807 13.042L.75 18l3.75.75 1.5 4.5 3.944-6.258M19.193 13.042L23.25 18l-3.75.75-1.5 4.5-3.944-6.258M20.25 9a8.246 8.246 0 11-4.5-7.35"
          transform="scale(3.33333)"
        ></path>
        <path
          d="M18 5.25l-5.47 5.47a.749.749 0 01-1.06 0L9.75 9"
          transform="scale(3.33333)"
        ></path>
      </g>
    </svg>;

export default Index;
