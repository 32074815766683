import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M16 16.76A3.25 3.25 0 0012.75 20v.38a.75.75 0 01-1.5 0v-.38a4.75 4.75 0 119.5 0v.38a.75.75 0 01-1.5 0v-.38c0-1.8-1.46-3.25-3.25-3.25z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M19.24 6.3c.4.13.6.55.47.95l-3 9a.75.75 0 01-1.42-.48l3-9c.13-.4.56-.6.95-.47zM7.25 10.51a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM24.25 15.51a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM22.35 10.03a1.25 1.25 0 012.4.48 1.25 1.25 0 11-2.4-.48zm1.15.73zM11.25 6.51a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM2.98 23.59a21.75 21.75 0 0126.04 0 .75.75 0 11-.9 1.2 20.25 20.25 0 00-24.25 0 .75.75 0 01-.9-1.2z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.25 16a15.75 15.75 0 1131.5 0 15.75 15.75 0 01-31.5 0zM16 1.77a14.25 14.25 0 100 28.5 14.25 14.25 0 000-28.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M5.25 15.51a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
