import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M28.26 6.97a2.75 2.75 0 013.5 2.65v9.55c.02 2.53-.91 4.99-2.61 6.87a21.1 21.1 0 01-8.2 5.54c-.6.22-1.28.22-1.89 0a21.13 21.13 0 01-8.2-5.53 10.12 10.12 0 01-2.6-6.88V9.62a2.75 2.75 0 013.48-2.65 30.1 30.1 0 0016.52 0zm.41 1.45a31.6 31.6 0 01-17.33 0 1.25 1.25 0 00-1.58 1.2v9.56a8.62 8.62 0 002.22 5.86c2.09 2.28 4.7 4.04 7.6 5.13.28.1.58.1.85 0a19.6 19.6 0 007.6-5.13 8.62 8.62 0 002.23-5.86V9.62a1.25 1.25 0 00-1.59-1.2z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M3 .25A2.75 2.75 0 00.25 3v9.55c-.02 2.53.9 4.99 2.6 6.87h.01c.24.27.5.52.75.78a.75.75 0 001.06-1.06 8.62 8.62 0 01-2.92-6.58V3a1.25 1.25 0 011.58-1.2 31.5 31.5 0 0017.34 0A1.25 1.25 0 0122.25 3v2.38a.75.75 0 001.5 0V3A2.75 2.75 0 0020.27.35 30.06 30.06 0 0111.1 1.5h-.1A30.06 30.06 0 013.72.35C3.5.28 3.25.25 3 .25zM16 20.25c.41 0 .75.34.75.75a3.25 3.25 0 006.5 0 .75.75 0 011.5 0 4.75 4.75 0 11-9.5 0c0-.41.34-.75.75-.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M15 13.75c-.69 0-1.25.56-1.25 1.25a.75.75 0 01-1.5 0 2.75 2.75 0 115.5 0 .75.75 0 01-1.5 0c0-.69-.56-1.25-1.25-1.25zM25 13.75c-.69 0-1.25.56-1.25 1.25a.75.75 0 01-1.5 0 2.75 2.75 0 115.5 0 .75.75 0 01-1.5 0c0-.69-.56-1.25-1.25-1.25z" clipRule="evenodd"/>
</svg>;

export default Index;
