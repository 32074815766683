import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
<path fill={fill} fillRule="evenodd" d="M1 18.234a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0v-12a.75.75 0 01.75-.75zM18.25 18.984a.75.75 0 01.75-.75h12a.75.75 0 01.75.75v11.333c0 .783-.634 1.417-1.417 1.417H19.667a1.417 1.417 0 01-1.417-1.417V18.984zm1.5.75v10.5h10.5v-10.5h-10.5z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M1.75 15.734v2.5h28.5v-2.5H1.75zm-1.5-.084c0-.782.634-1.416 1.417-1.416h28.666c.783 0 1.417.634 1.417 1.416v3.334a.75.75 0 01-.75.75H1a.75.75 0 01-.75-.75V15.65zM18.25 24.984a.75.75 0 01.75-.75h12a.75.75 0 010 1.5H19a.75.75 0 01-.75-.75zM4.25 2.484A2.25 2.25 0 016.5.234h13a2.25 2.25 0 012.25 2.25v7a2.25 2.25 0 01-2.25 2.25h-13a2.25 2.25 0 01-2.25-2.25v-7zm2.25-.75a.75.75 0 00-.75.75v7c0 .414.336.75.75.75h13a.75.75 0 00.75-.75v-7a.75.75 0 00-.75-.75h-13z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M13 10.234a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75z" clipRule="evenodd"/>
</svg>;

export default Index;
