import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path fill={fill} d="M28.5467 15.4401L16.5467 3.44008C16.0667 2.96008 15.4 2.66675 14.6667 2.66675H5.33335C3.86669 2.66675 2.66669 3.86675 2.66669 5.33341V14.6667C2.66669 15.4001 2.96002 16.0667 3.45335 16.5601L15.4534 28.5601C15.9334 29.0401 16.6 29.3334 17.3334 29.3334C18.0667 29.3334 18.7334 29.0401 19.2134 28.5467L28.5467 19.2134C29.04 18.7334 29.3334 18.0667 29.3334 17.3334C29.3334 16.6001 29.0267 15.9201 28.5467 15.4401ZM7.33335 9.33342C6.22669 9.33342 5.33335 8.44008 5.33335 7.33342C5.33335 6.22675 6.22669 5.33341 7.33335 5.33341C8.44002 5.33341 9.33335 6.22675 9.33335 7.33342C9.33335 8.44008 8.44002 9.33342 7.33335 9.33342Z" />
</svg>;

export default Index;


