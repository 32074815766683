import React from 'react'

const Index = props => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <defs />
    <path
        fill={props.fill}
        fillRule="evenodd"
        d="M7.41 16.41L12 11.83l4.59 4.58L18 15l-6-6-6 6 1.41 1.41z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
