import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M3 3.75c-.69 0-1.25.56-1.25 1.25v16c0 .69.56 1.25 1.25 1.25h8a.75.75 0 010 1.5H3A2.75 2.75 0 01.25 21V5A2.75 2.75 0 013 2.25h18A2.75 2.75 0 0123.75 5v6a.75.75 0 01-1.5 0V5c0-.69-.56-1.25-1.25-1.25H3z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M.25 9A.75.75 0 011 8.25h22a.75.75 0 010 1.5H1A.75.75 0 01.25 9zM7 .25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V1A.75.75 0 017 .25zM17 .25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V1A.75.75 0 0117 .25zM14.25 23a8.75 8.75 0 1117.5 0 8.75 8.75 0 01-17.5 0zM23 15.75a7.25 7.25 0 100 14.5 7.25 7.25 0 000-14.5z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M23 18.715a.75.75 0 01.75.75v2.785h2.786a.75.75 0 010 1.5H23a.75.75 0 01-.75-.75v-3.535a.75.75 0 01.75-.75z" clipRule="evenodd"/>
</svg>;

export default Index;
