import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M11.75 10.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zM16 7.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M10.25 20a5.75 5.75 0 0111.5 0v3a.75.75 0 01-.75.75h-1.338l-.918 7.343a.75.75 0 01-.744.657h-4a.75.75 0 01-.744-.657l-.918-7.343H11a.75.75 0 01-.75-.75v-3zM16 15.75A4.25 4.25 0 0011.75 20v2.25H13a.75.75 0 01.744.657l.918 7.343h2.676l.918-7.343A.75.75 0 0119 22.25h1.25V20A4.25 4.25 0 0016 15.75zM23.55 9.39a.75.75 0 011.05.15 10.767 10.767 0 011.256 10.76.75.75 0 01-1.376-.6 9.267 9.267 0 00-1.08-9.26.75.75 0 01.15-1.05zM8.45 9.39a.75.75 0 01.15 1.05 9.267 9.267 0 00-1.08 9.26.75.75 0 01-1.376.6A10.767 10.767 0 017.4 9.54a.75.75 0 011.05-.15z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M25.588 5.459c-5.436-4.945-13.74-4.945-19.177 0C.975 10.403.191 18.67 4.6 24.55a.75.75 0 11-1.2.9C-1.473 18.952-.606 9.814 5.402 4.349s15.187-5.465 21.196 0c6.008 5.465 6.875 14.603 2.002 21.101a.75.75 0 11-1.2-.9c4.409-5.879 3.625-14.147-1.812-19.091z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
