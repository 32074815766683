import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) =>    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.24 230.24">
<g data-name="Layer 2">
  <g data-name="Layer 1">
    <path fill={fill} fillRule="evenodd"
      d="M43.22 130.88h28.94V69.17H43.22zm33.18 4.86H39V64.31h37.4z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M77 136.35H38.37V63.7H77zm-37.42-1.21H75.8V64.91H39.58zm33.19-3.66H42.61V68.56h30.16zm-28.95-1.21h27.74v-60.5H43.82z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M54.84 110.77L65.39 92.89 59.43 95.38 60.97 87.66 50.41 104.08 56.61 101.99 54.84 110.77z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M53.54 114.16l2.27-11.26-6.81 2.31 13.2-20.56-1.94 9.74 6.6-2.76zm3.87-13.08l-1.27 6.3L64 94.15l-5.33 2.23 1.13-5.72L51.85 103zM167 147.66H95.4v-26.11a7 7 0 017.07-7h76.69a7 7 0 017.06 7v26.11zm19.18 12.73h-14.09v-7.68h14.13zm-76.68 0H95.41v-7.68h14.13zm7.26-70.64h48L175 109.49h-68.39zm64 19.9l-12.87-25h-54.17l-12.87 25a12.06 12.06 0 00-10.49 11.9v43.88h24.22v-12.72H167v12.72h24.23v-43.88a12.07 12.07 0 00-10.49-11.9"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M191.88 166h-25.44v-12.69h-51.25V166H89.75v-44.45a12.7 12.7 0 0110.71-12.45l12.89-25h54.92l.17.32 12.73 24.68a12.7 12.7 0 0110.71 12.45zm-24.23-1.2h23v-43.25a11.5 11.5 0 00-10-11.3h-.31l-.15-.28-12.65-24.67h-53.46l-12.84 24.91h-.31a11.5 11.5 0 00-10 11.3v43.28h23V152.1h53.67zm19.17-3.8h-15.33v-8.9h15.33zm-14.13-1.21h12.93v-6.47h-12.93zM110.14 161H94.8v-8.9h15.34zM96 159.78h12.93v-6.47H96zm90.81-11.51h-92v-26.72a7.65 7.65 0 017.67-7.62h76.68a7.65 7.65 0 017.67 7.62zM96 147.06h89.61v-25.51a6.45 6.45 0 00-6.47-6.41h-76.67a6.45 6.45 0 00-6.47 6.41zm80-37h-70.38l10.81-21h48.77zm-68.4-1.21H174l-9.55-18.54h-47.29z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M124.85 128.57H159.98V133.62H124.85z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M160.58 134.23h-36.33V128h36.33zM125.45 133h33.93v-3.84h-33.93z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M124.85 138.12H159.98V143.16H124.85z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M160.58 143.76h-36.33v-6.25h36.33zm-35.13-1.2h33.93v-3.84h-33.93zM108.83 131.76a3.84 3.84 0 113.87-3.84 3.86 3.86 0 01-3.87 3.84m0-12.73a8.89 8.89 0 108.92 8.89 8.92 8.92 0 00-8.92-8.89"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M108.83 137.4a9.49 9.49 0 119.52-9.48 9.51 9.51 0 01-9.52 9.48zm0-17.76a8.28 8.28 0 108.31 8.28 8.3 8.3 0 00-8.31-8.28zm0 12.72a4.45 4.45 0 114.47-4.44 4.46 4.46 0 01-4.47 4.44zm0-7.68a3.24 3.24 0 103.26 3.24 3.26 3.26 0 00-3.26-3.24zM172.76 131.76a3.84 3.84 0 113.87-3.84 3.87 3.87 0 01-3.87 3.84m0-12.73a8.89 8.89 0 108.92 8.89 8.91 8.91 0 00-8.92-8.89"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M172.76 137.4a9.49 9.49 0 119.52-9.48 9.51 9.51 0 01-9.52 9.48zm0-17.76a8.28 8.28 0 108.32 8.28 8.3 8.3 0 00-8.32-8.28zm0 12.72a4.45 4.45 0 114.47-4.44 4.46 4.46 0 01-4.47 4.44zm0-7.68a3.24 3.24 0 103.27 3.24 3.25 3.25 0 00-3.27-3.24zM76 108.72a13.92 13.92 0 016.4-3.88 12.77 12.77 0 018.24.82c4.32 2 8.12 5.32 13.13 5.08 2.26-.1 2.28-3.64 0-3.53-5.32.25-9.46-4.47-14.44-5.74-5.94-1.51-11.57.56-15.83 4.76-1.62 1.6.87 4.09 2.5 2.49"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M103.25 111.36c-3.51 0-6.45-1.66-9.3-3.27-1.16-.66-2.36-1.33-3.57-1.88a12.16 12.16 0 00-7.84-.78 13.14 13.14 0 00-6.13 3.72 2.37 2.37 0 01-3.35-3.35c4.73-4.66 10.56-6.4 16.41-4.91a24.12 24.12 0 016 2.79c2.72 1.58 5.28 3.06 8.25 2.93a2.09 2.09 0 012 1 2.57 2.57 0 01.09 2.52 2.29 2.29 0 01-2 1.25zm-18-7.47a13.72 13.72 0 015.6 1.22c1.26.57 2.48 1.26 3.66 1.93 3 1.67 5.76 3.23 9.19 3.1a1.12 1.12 0 001-.6 1.4 1.4 0 000-1.32.94.94 0 00-.89-.41c-3.29.17-6.16-1.49-8.92-3.09a23.38 23.38 0 00-5.7-2.66c-5.43-1.38-10.85.25-15.26 4.6a1.06 1.06 0 000 1.58 1.11 1.11 0 001.63.05 14.27 14.27 0 016.68-4 12.88 12.88 0 013.04-.4z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M115.12 230.24a115.12 115.12 0 11115.12-115.12 115.25 115.25 0 01-115.12 115.12zm0-223A107.88 107.88 0 10223 115.12 108 108 0 00115.12 7.24z"
      className="cls-1"
    />
  </g>
</g>
</svg>;

export default Index;
