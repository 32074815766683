import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs/>
    <path fill={fill} fillRule="evenodd" d="M12.93 5.37a4.75 4.75 0 013.88-1.05 4.9 4.9 0 013.94 4.9v1.04a11.76 11.76 0 016.95 9.61v.03a1.74 1.74 0 01-1.76 1.85H6.06a1.74 1.74 0 01-1.76-1.88c.42-4.23 3.08-7.9 6.95-9.6V9c0-1.4.62-2.73 1.68-3.63zm3.6.42A3.25 3.25 0 0012.75 9v1.76c0 .31-.19.59-.48.7-3.59 1.4-6.1 4.7-6.47 8.54a.24.24 0 00.24.25h19.91a.24.24 0 00.25-.25 10.26 10.26 0 00-6.47-8.54.75.75 0 01-.48-.7V9.2a3.4 3.4 0 00-2.72-3.4z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M16 .25c.41 0 .75.34.75.75v4a.75.75 0 01-1.5 0V1c0-.41.34-.75.75-.75zM12 20.25c.41 0 .75.34.75.75a3.25 3.25 0 006.5 0 .75.75 0 011.5 0 4.75 4.75 0 11-9.5 0c0-.41.34-.75.75-.75zM16 28.25c.41 0 .75.34.75.75v2a.75.75 0 01-1.5 0v-2c0-.41.34-.75.75-.75zM22.58 26.38a.75.75 0 011.04.2l2 3a.75.75 0 11-1.24.84l-2-3a.75.75 0 01.2-1.04zM9.42 26.38c.34.23.43.7.2 1.04l-2 3a.75.75 0 11-1.24-.84l2-3a.75.75 0 011.04-.2z" clipRule="evenodd"/>
</svg>;

export default Index;
