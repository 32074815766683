import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <defs/>
    <path fill={fill} fillRule="evenodd" d="M10.13 24c.42 0 .75.34.75.75v5.01a.75.75 0 01-1.5 0v-5c0-.42.34-.76.75-.76zM17.96 24c.41 0 .75.34.75.75v5.01a.75.75 0 01-1.5 0v-5c0-.42.33-.76.75-.76z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M6.77 24.75c0-.41.34-.75.75-.75h13.05a.75.75 0 110 1.5H7.52a.75.75 0 01-.75-.75z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M1.75 8.93v20.83a.75.75 0 01-1.5 0V8.91v-.04a2.07 2.07 0 012.16-1.94H13.6a2.07 2.07 0 012.16 1.98v11.25a.75.75 0 01-1.5 0V8.93c-.02-.27-.27-.52-.62-.5H2.37c-.35-.02-.6.23-.62.5z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M5.77 3.09h4.46a1.45 1.45 0 011.52 1.39v3.2c0 .41-.34.75-.75.75H5a.75.75 0 01-.75-.75v-3.2-.05c.05-.8.75-1.37 1.52-1.34zm-.02 1.5v2.34h4.5V4.59h-4.5z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M8.01.42c.41 0 .75.34.74.76l-.03 2.3a.75.75 0 11-1.5-.03l.03-2.3c0-.4.35-.74.76-.73zM4.25 12.48c0-.41.34-.75.75-.75h6a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zM4.25 18.24c0-.41.34-.75.75-.75h6a.75.75 0 010 1.5H5a.75.75 0 01-.75-.75zM.25 29.76c0-.41.34-.75.75-.75h30a.75.75 0 010 1.5H1a.75.75 0 01-.75-.75zM20.25 16.32c0-3.1 2.6-5.55 5.75-5.55a5.65 5.65 0 015.75 5.55c0 3.1-2.6 5.55-5.75 5.55a5.65 5.65 0 01-5.75-5.55zM26 12.27a4.15 4.15 0 00-4.25 4.05c0 2.2 1.87 4.05 4.25 4.05a4.15 4.15 0 004.25-4.05c0-2.2-1.87-4.05-4.25-4.05z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M26 16.53c.41 0 .75.34.75.75v12.48a.75.75 0 01-1.5 0V17.28c0-.41.34-.75.75-.75z" clipRule="evenodd"/>
</svg>;

export default Index;
