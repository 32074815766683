import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 80" xmlSpace="preserve">
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        transform="scale(3.33333)"
      >
        <path d="M15.75 23.24a3 3 0 00-3-3H9a3 3 0 00-3-3H.75v6z"></path>
        <path d="M6 20.24L9 20.24"></path>
        <path d="M16.5 17.74H21a.76.76 0 00.75-.75v-6.75"></path>
        <path d="M8.25 10.24L8.25 14.24"></path>
        <rect width="16.5" height="4.25" x="6.75" y="5.99" rx="0.75"></rect>
        <path d="M15 15.24L15 5.99"></path>
        <path d="M19.27 3.87A11.84 11.84 0 0115 6a11.93 11.93 0 012.12-4.24C18.46.43 19.21.63 19.8 1.22s.78 1.33-.53 2.65zM10.73 3.87A11.84 11.84 0 0015 6a11.93 11.93 0 00-2.12-4.24C11.54.43 10.79.63 10.2 1.22s-.78 1.33.53 2.65z"></path>
      </g>
    </svg>;

export default Index;
