import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path fill={fill} fillRule="evenodd" d="M6 31c3.2-1.6 7.4-2.5 12-2.5s8.8 1 12 2.5c3 1.5 5.5 4 5.5 7S33 43.5 30 45a27.4 27.4 0 01-12 2.5c-4.6 0-8.8-1-12-2.5-3-1.5-5.5-4-5.5-7S3 32.5 6 31zm1.4 2.7c-2.8 1.4-3.9 3-3.9 4.3 0 1.3 1.1 3 3.9 4.3 2.6 1.3 6.4 2.2 10.6 2.2 4.2 0 8-.9 10.6-2.2 2.8-1.4 3.9-3 3.9-4.3 0-1.3-1.1-3-3.9-4.3A24.5 24.5 0 0018 31.5c-4.2 0-8 .9-10.6 2.2z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M2 36.5c.8 0 1.5.7 1.5 1.5v16c0 1.3 1.1 3 3.9 4.3 2.6 1.3 6.4 2.2 10.6 2.2 4.2 0 8-.9 10.6-2.2 2.8-1.4 3.9-3 3.9-4.3V38a1.5 1.5 0 013 0v16c0 3-2.5 5.5-5.5 7a27.4 27.4 0 01-12 2.5c-4.6 0-8.8-1-12-2.5-3-1.5-5.5-4-5.5-7V38c0-.8.7-1.5 1.5-1.5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M2 44.5c.8 0 1.5.7 1.5 1.5 0 1.3 1.1 3 3.9 4.3 2.6 1.3 6.4 2.2 10.6 2.2 4.2 0 8-.9 10.6-2.2 2.8-1.4 3.9-3 3.9-4.3a1.5 1.5 0 013 0c0 3-2.5 5.5-5.5 7a27.4 27.4 0 01-12 2.5c-4.6 0-8.8-1-12-2.5-3-1.5-5.5-4-5.5-7 0-.8.7-1.5 1.5-1.5zM.5 6C.5 3 3 .5 6 .5h52c3 0 5.5 2.5 5.5 5.5v32c0 3-2.5 5.5-5.5 5.5H42a1.5 1.5 0 010-3h16c1.4 0 2.5-1.1 2.5-2.5V6c0-1.4-1.1-2.5-2.5-2.5H6A2.5 2.5 0 003.5 6v20a1.5 1.5 0 01-3 0V6z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M37.8 19a6.5 6.5 0 00-12.3 3 1.5 1.5 0 01-3 0 9.5 9.5 0 1115.8 7.1 1.5 1.5 0 11-2-2.2c2.2-2 2.8-5.2 1.5-7.9zM12 9.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM52 29.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" clipRule="evenodd" />
</svg>;

export default Index;
