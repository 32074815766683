import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M.534 2.368a.75.75 0 011.054.122l2 2.523a.75.75 0 01.162.466V11.452c-.02.827.451 1.707 1.368 2.714.776.852 1.773 1.69 2.824 2.575l.541.457a.75.75 0 01-.97 1.145L6.99 17.9c-1.046-.88-2.131-1.793-2.981-2.725-.983-1.08-1.789-2.33-1.759-3.75V5.74L.412 3.422a.75.75 0 01.122-1.054zM6.27.377a.75.75 0 011.041.205 9.131 9.131 0 011.42 3.559.75.75 0 01.012.126V5.91l3.455 3.526a2.374 2.374 0 01-3.4 3.314l-2.36-2.416a.75.75 0 011.074-1.048l2.359 2.416a.876.876 0 001.467-.372.874.874 0 00-.214-.847L7.457 6.742a.75.75 0 01-.214-.525V4.331a7.631 7.631 0 00-1.177-2.913.75.75 0 01.205-1.04z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M8 10.1a.75.75 0 01.75.75V27c0 .69.56 1.25 1.25 1.25h10a.75.75 0 010 1.5H10A2.75 2.75 0 017.25 27V10.85A.75.75 0 018 10.1zM24.684 14.392a.75.75 0 01.8.107c1.049.886 2.111 1.814 2.903 2.782.787.963 1.389 2.062 1.363 3.293v5.685l1.837 2.318a.75.75 0 01-1.175.932l-2-2.523a.75.75 0 01-.162-.466v-5.973c.017-.71-.328-1.465-1.024-2.316-.41-.502-.914-1.003-1.476-1.513v5.445a.75.75 0 01-1.287.524l-2.333-2.39a.875.875 0 00-1.254 1.219l3.672 3.743a.75.75 0 01.215.525v1.884a7.64 7.64 0 001.177 2.909.75.75 0 11-1.246.836 9.142 9.142 0 01-1.42-3.553.746.746 0 01-.011-.127V26.09l-3.46-3.527a2.374 2.374 0 013.4-3.314l1.047 1.072v-5.249a.75.75 0 01.434-.68z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M11.25 24a.75.75 0 01.75-.75h5.304a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM11.25 19a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM21 1.75A5.25 5.25 0 1026.25 7a.75.75 0 011.5 0A6.75 6.75 0 1121 .25a.75.75 0 010 1.5z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M27.53 1.968a.75.75 0 010 1.061l-5.465 5.465v.001a1.502 1.502 0 01-2.13 0l-1.717-1.72a.75.75 0 111.06-1.06l1.72 1.722h.004l.001-.002 5.467-5.467a.75.75 0 011.06 0z" clipRule="evenodd"/>
</svg>;

export default Index;
