import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M13.8 9a8.7 8.7 0 1117.4 0 8.7 8.7 0 01-17.4 0zm8.7-7.3a7.2 7.2 0 100 14.5 7.2 7.2 0 000-14.4z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M19.8 5c0-.4.3-.8.7-.8h2a2.7 2.7 0 110 5.5h-1.3V13a.8.8 0 01-1.4 0V5zm1.4 3.3h1.3a1.2 1.2 0 100-2.6h-1.3v2.5zM9.6 11.8a2.3 2.3 0 00-2.1 1.4L5 18.4a.7.7 0 11-1.3-.6L6 12.7c.5-1.5 2-2.5 3.5-2.4h.9a.8.8 0 010 1.4h-1zM2.5 26c.4 0 .8.3.8.7V29a1.2 1.2 0 102.5 0v-2a.8.8 0 011.5 0v2a2.7 2.7 0 11-5.5 0v-2.3c0-.4.3-.7.7-.7zM28.5 26c.4 0 .8.3.8.7V29a2.7 2.7 0 11-5.6 0v-2a.8.8 0 011.6 0v2a1.2 1.2 0 102.4 0v-2.3c0-.4.4-.7.8-.7zM24.3 22c0-.4.3-.8.7-.8.7 0 1.3.6 1.3 1.3a.8.8 0 01-1.5.2.8.8 0 01-.6-.7z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M25.2 22.7a.8.8 0 00-.2-1.4c-.7 0-1.3.5-1.3 1.2a.8.8 0 001.5.2z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M25.2 22.3a.8.8 0 00-1.4.2c0 .7.5 1.3 1.2 1.3a.8.8 0 00.2-1.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M25.5 21.8c.4 0 .8.3.8.7 0 .7-.6 1.3-1.3 1.3a.8.8 0 01-.2-1.5c0-.3.4-.6.7-.6zM4.8 22.5c0-.7.5-1.3 1.2-1.3a.8.8 0 01.2 1.5.8.8 0 01-1.5-.2z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M5.3 22c0-.4.3-.8.7-.8.7 0 1.3.6 1.3 1.3a.8.8 0 01-1.5.2.8.8 0 01-.5-.7z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M6.5 21.8c.4 0 .8.3.8.7 0 .7-.6 1.3-1.3 1.3a.8.8 0 01-.2-1.5c0-.3.4-.6.7-.6z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M6.2 22.3a.8.8 0 00-1.5.2c0 .7.6 1.3 1.3 1.3a.8.8 0 00.2-1.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.8 22c0-2.6 2-4.8 4.7-4.8h9a.8.8 0 010 1.6h-9A3.3 3.3 0 002.2 22v3c0 .7.6 1.3 1.3 1.3h24c.7 0 1.3-.6 1.3-1.3v-3c0-.6-.2-1.1-.5-1.6a.8.8 0 011.3-.8c.4.7.7 1.6.6 2.4h-.7.8v3c0 1.5-1.3 2.8-2.8 2.8h-24A2.7 2.7 0 01.7 25v-3z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
