import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M20.5 1.75c-4 0-7.25 3.25-7.25 7.25a.75.75 0 01-1.5 0 8.75 8.75 0 1117.5 0v22a.75.75 0 01-1.5 0V9c0-4-3.25-7.25-7.25-7.25z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M7.3 14.25h10.4a5.25 5.25 0 00-10.4 0zM5.75 15a6.75 6.75 0 0113.5 0 .75.75 0 01-.75.75h-12a.75.75 0 01-.75-.75zM7.74 18.29c.39.13.6.55.47.95l-1 3a.75.75 0 01-1.42-.48l1-3c.13-.39.55-.6.95-.47zM4.74 27.29c.39.13.6.55.47.95l-1 3a.75.75 0 01-1.42-.48l1-3c.13-.39.55-.6.95-.47zM17.26 18.29c.4-.13.82.08.95.47l1 3a.75.75 0 01-1.42.48l-1-3a.75.75 0 01.47-.95zM20.26 27.29c.4-.13.82.08.95.47l1 3a.75.75 0 01-1.42.48l-1-3a.75.75 0 01.47-.95zM12.5 18.25c.41 0 .75.34.75.75v3a.75.75 0 01-1.5 0v-3c0-.41.34-.75.75-.75zM12.5 27.25c.41 0 .75.34.75.75v3a.75.75 0 01-1.5 0v-3c0-.41.34-.75.75-.75z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
