import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M18 .3c.5 0 .9.2.9.6 0 1.3.6 2.5 1.5 3.4l1.4 1.4a.8.8 0 010 1l-6.3 6.4a1.3 1.3 0 001.8 1.8l8.4-8.5a.7.7 0 011.3.5l-.2 4a1.3 1.3 0 002.6 0l.4-5.5a.8.8 0 011.4.1l-.3 5.6a2.8 2.8 0 11-5.6-.4l.1-1.9-7 7.1a2.8 2.8 0 01-4-3.8l5.9-5.9-1-.9a6.7 6.7 0 01-2-4.2c0-.5.4-.8.8-.8zM9.4 18.2c.4 0 .8.4.8.8v4a.8.8 0 01-1.5 0v-4c0-.4.3-.8.7-.8z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M3.7 19c0-.4.3-.8.7-.8h10a.8.8 0 010 1.5h-10a.8.8 0 01-.7-.7zM9.4 23.7A7.3 7.3 0 002.3 30l-.8-.1h.8a.3.3 0 00.2.3h13.9a.3.3 0 00.2-.2 7.2 7.2 0 00-7.2-6.3zm-8.6 6a8.8 8.8 0 0117.3 0 1.8 1.8 0 01-1.7 2H2.5a1.8 1.8 0 01-1.7-2z" clipRule="evenodd"/>
</svg>;

export default Index;
