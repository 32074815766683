import React from 'react';
import colors from '../../../styles/settings/colors'

const SubDirectoryRight = ({ fill = colors.base.blue }) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={fill} d="M25.3334 18.2746L17.8667 25.3334L16.0996 23.6628L20.5671 19.451H6.66669L6.66669 5.33337H9.15558V17.0981H20.5671L16.0996 12.8863L17.8667 11.2157L25.3334 18.2746Z" />
    </svg>
)

export default SubDirectoryRight;
