import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M8.2 1c0-.4.4-.8.8-.8h14a.8.8 0 010 1.6H9a.8.8 0 01-.8-.8z" clipRule="evenodd" />
    <path fill={fill} d="M26.6 31.8H5.3a5 5 0 01-4.2-2.6c-.8-1.4-.8-3.3.2-4.8l9-13.6V1c0-.4.3-.8.7-.8h10c.4 0 .8.4.8.9v9.7l8.8 13.6c1 1.6 1 3.4.2 5a4.9 4.9 0 01-4.2 2.4zm-14.9-30v9.5l-9 14c-.7 1-.8 2.3-.2 3.3.6 1 1.7 1.6 3 1.6h21.1c1.2 0 2.3-.6 3-1.6.5-1 .4-2.4-.2-3.4l-9-13.8V1.8h-8.7z" />
    <path fill={fill} fillRule="evenodd" d="M6.3 17c0-.4.4-.8.8-.8h17.8a.8.8 0 010 1.6H7a.8.8 0 01-.8-.8zM18.2 23c0-.4.4-.8.8-.8h4a.8.8 0 010 1.6h-4a.8.8 0 01-.8-.8z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M21 20.3c.4 0 .7.3.7.7v4a.8.8 0 01-1.5 0v-4c0-.4.4-.8.8-.8zM8.2 25.5c0-.4.4-.8.8-.8.7 0 1.2.6 1.2 1.3a.7.7 0 01-1.5.2.8.8 0 01-.5-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M9.2 26.2a.8.8 0 00-.2-1.4c-.7 0-1.3.5-1.3 1.2a.8.8 0 001.5.2z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M9.2 25.8a.8.8 0 00-1.5.2c0 .7.6 1.3 1.3 1.3a.8.8 0 00.2-1.5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M9.5 25.3c.4 0 .7.3.7.7 0 .7-.5 1.3-1.2 1.3a.8.8 0 01-.3-1.5c.1-.3.4-.6.8-.6zM12.2 21.5c0-.4.4-.8.8-.8.7 0 1.2.6 1.2 1.3a.8.8 0 01-1.5.2.8.8 0 01-.5-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M13.2 22.2a.8.8 0 00-.2-1.4c-.7 0-1.3.5-1.3 1.2a.8.8 0 001.5.2z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M13.2 21.8a.8.8 0 00-1.5.2c0 .7.6 1.3 1.3 1.3a.8.8 0 00.2-1.5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M13.5 21.3c.4 0 .7.3.7.7 0 .7-.5 1.3-1.2 1.3a.8.8 0 01-.3-1.5c.1-.3.4-.6.8-.6zM16.2 5c0-.4.4-.8.8-.8h4a.8.8 0 010 1.5h-4a.8.8 0 01-.8-.7zM16.2 9c0-.4.4-.8.8-.8h4a.8.8 0 010 1.6h-4a.8.8 0 01-.8-.8z" clipRule="evenodd" />
</svg>;

export default Index;
