import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M9.9 3.2a7.7 7.7 0 0112.2 0 4.7 4.7 0 014.6 5A7.7 7.7 0 0125 22.5a6.2 6.2 0 01-8.9 2.2 6.2 6.2 0 01-9-2.2A7.7 7.7 0 015.3 8.2a4.7 4.7 0 014.6-5zM16 1.7c-2 0-4 1-5.1 2.8-.2.2-.4.3-.7.3a1.7 1.7 0 01-.2 0 3.2 3.2 0 00-3.2 3.7c0 .4-.1.7-.4.8a6.2 6.2 0 001.3 11.8c.2 0 .4.2.5.4a4.7 4.7 0 007.3 1.6c.3-.2.7-.2 1 0a4.7 4.7 0 007.3-1.6c0-.2.3-.3.5-.4a6.2 6.2 0 001.3-11.8.7.7 0 01-.4-.8A3.2 3.2 0 0022 4.7h-.2a.8.8 0 01-.7-.2A6.2 6.2 0 0016 1.7z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M16 10.3c.4 0 .8.3.8.7v20a.8.8 0 01-1.6 0V11c0-.4.4-.8.8-.8z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M22 13.3c.4 0 .8.3.8.7 0 2.1-.6 3.8-1.8 5-1.2 1.2-2.9 1.8-5 1.8a.8.8 0 010-1.6c1.9 0 3.2-.4 4-1.2.8-.8 1.3-2.1 1.3-4 0-.4.3-.8.7-.8zM11 10.3c.5 0 .8.3.7.7a4 4 0 004.3 4.3.7.7 0 110 1.4 5.5 5.5 0 01-5.7-5.7c0-.5.3-.8.7-.7z" clipRule="evenodd"/>
</svg>;

export default Index;
