import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) =>    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.24 230.24">
<path fill={fill} fillRule="evenodd"
  d="M115.5 82.79c3.47-4.22 6.86-8.38 10.31-12.49 1.93-2.31 3.94-4.57 6-6.8a3.08 3.08 0 014.88.06c8.76 9.81 17.2 19.87 24.38 30.93a111.2 111.2 0 0112.44 24.74c3.17 9.36 4.54 19 2.68 28.8-2.62 13.85-9.49 25.05-21.73 32.32-11.47 6.81-23.63 7.6-36 2.31-9.52-4.08-16.49-11-21.24-20.17a58.61 58.61 0 01-3.11-7.3 1.61 1.61 0 00-2.11-1.26 30.69 30.69 0 01-12.15-.5c-10.39-2.45-18.18-8.49-23.69-17.56a41.1 41.1 0 01-5.93-22 57.85 57.85 0 014.3-20.46 117.57 117.57 0 0116.7-29.17c4.31-5.62 9-10.95 13.59-16.35 1.62-1.9 3.61-1.84 5.26 0a182.08 182.08 0 0120.68 26.88c1.63 2.6 3.14 5.3 4.74 8.02zm18.71-12.68c-4.48 5.57-8.74 10.72-12.85 16a138.47 138.47 0 00-18.14 29.69 60.41 60.41 0 00-5.3 19.56c-.79 10.31 1.43 20 7.43 28.47 6.64 9.41 15.55 15.1 27.33 15.73a32.49 32.49 0 0019.65-5.31 38.38 38.38 0 0014.72-17.75 44.32 44.32 0 002.72-26.07 74.08 74.08 0 00-7.07-19.92 141.1 141.1 0 00-14-22.15c-4.6-6.07-9.46-11.96-14.49-18.25zM92 147.54c-.11-1-.22-1.88-.31-2.79s-.2-1.95-.25-2.92c-.57-11.39 2.55-22 7.31-32.12a144.33 144.33 0 0112-20.31 1.53 1.53 0 00.17-1.27 93.51 93.51 0 00-4.85-8.71c-4.11-6-8.39-12-12.72-17.89-1.77-2.42-3.84-4.61-5.81-7a8.69 8.69 0 00-.79.75c-4 5.07-8.19 10-12 15.24-6.38 8.89-11.85 18.28-15.4 28.68-2.6 7.64-3.84 15.42-1.81 23.4 3.15 12.45 10.55 21 23.13 24.5a25.48 25.48 0 0011.33.44z"
  className="cls-1"
/>
<path fill={fill} fillRule="evenodd"
  d="M110.61 133.66c2.14.1 3.28 1.33 3.1 3.44-.83 10 3.34 17.7 10.81 23.91 1.59 1.32 2.09 3.19 1 4.64a3.33 3.33 0 01-4.85.46 32.83 32.83 0 01-13.43-22.53 31.31 31.31 0 01.2-7.42 2.89 2.89 0 013.17-2.5zM64.34 112.4a21.19 21.19 0 01.41-3.41 2.93 2.93 0 013.42-2.28 3.17 3.17 0 012.69 3.3c-.36 6.71 1.86 12.64 5.48 18.11a22.79 22.79 0 004 4.27c1.5 1.34 2 3.29 1 4.68a3.27 3.27 0 01-4.93.41c-6.81-5.43-10.41-12.62-11.67-21.09-.1-.66-.2-1.32-.24-2s0-1.34 0-2z"
  className="cls-1"
/>
<path fill={fill} fillRule="evenodd"
  d="M115.12 230.24a115.12 115.12 0 11115.12-115.12 115.25 115.25 0 01-115.12 115.12zm0-223A107.88 107.88 0 10223 115.12 108 108 0 00115.12 7.24z"
  className="cls-1"
/>
</svg>;

export default Index;
