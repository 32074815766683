import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M3.75 7.47v21.54h12.5V7.47H3.75zm-1.5-.11c0-.796.663-1.39 1.417-1.39h12.666c.754 0 1.417.594 1.417 1.39v22.4a.75.75 0 01-.75.75H3a.75.75 0 01-.75-.75V7.36z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M7.75 3.63v2.34h4.5V3.63h-4.5zm-1.5-.11c0-.796.663-1.39 1.417-1.39h4.666c.754 0 1.417.594 1.417 1.39v3.2a.75.75 0 01-.75.75H7a.75.75 0 01-.75-.75v-3.2zM6.25 10.56A.75.75 0 017 9.81h6a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6.25 14.4a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6.25 18.24a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6.25 22.08a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75zM6.25 25.92a.75.75 0 01.75-.75h6a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M11 .21a.75.75 0 01.75.75v1.92a.75.75 0 01-1.5 0V.96A.75.75 0 0111 .21zM20.25 13.44c0-.973.813-1.71 1.75-1.71h6c.937 0 1.75.737 1.75 1.71v16.32a.75.75 0 01-.75.75h-8a.75.75 0 01-.75-.75V13.44zm1.5 15.57h6.5V13.44c0-.087-.083-.21-.25-.21h-6c-.167 0-.25.123-.25.21v15.57z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M27 9.81a.75.75 0 01.75.75v1.92a.75.75 0 01-1.5 0v-1.92a.75.75 0 01.75-.75zM.25 29.76a.75.75 0 01.75-.75h30a.75.75 0 010 1.5H1a.75.75 0 01-.75-.75z" clipRule="evenodd" />
</svg>;

export default Index;


