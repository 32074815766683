import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M12.25 2c0-.97.78-1.75 1.75-1.75h4c.97 0 1.75.78 1.75 1.75v4.25H24c.97 0 1.75.78 1.75 1.75v4c0 .97-.78 1.75-1.75 1.75h-4.25V18c0 .97-.78 1.75-1.75 1.75h-4c-.97 0-1.75-.78-1.75-1.75v-4.25H8c-.97 0-1.75-.78-1.75-1.75V8c0-.97.78-1.75 1.75-1.75h4.25V2zM14 1.75a.25.25 0 00-.25.25v5c0 .41-.34.75-.75.75H8a.25.25 0 00-.25.25v4c0 .14.11.25.25.25h5c.41 0 .75.34.75.75v5c0 .14.11.25.25.25h4c.14 0 .25-.11.25-.25v-5c0-.41.34-.75.75-.75h5c.14 0 .25-.11.25-.25V8a.25.25 0 00-.25-.25h-5a.75.75 0 01-.75-.75V2a.25.25 0 00-.25-.25h-4zM3 15.93c-.69 0-1.25.56-1.25 1.25v6.9c0 1.19.4 2.34 1.14 3.27l2.68 3.17a.75.75 0 01-1.14.96l-2.69-3.17-.01-.02a6.75 6.75 0 01-1.48-4.21v-6.9a2.75 2.75 0 115.5 0v5a.75.75 0 01-1.5 0v-5c0-.69-.56-1.25-1.25-1.25z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M5.42 20.18a2.62 2.62 0 013.68.8l1.51 2.27A6.75 6.75 0 0111.75 27v4a.75.75 0 01-1.5 0v-4a5.25 5.25 0 00-.88-2.92L7.84 21.8l-.01-.03a1.12 1.12 0 00-1.97 1.05l1.79 2.98a.75.75 0 11-1.29.77l-1.8-3a2.62 2.62 0 01.86-3.4zM26.25 17.18a2.75 2.75 0 115.5 0v6.9c0 1.53-.52 3.02-1.48 4.21l-.01.02-2.69 3.17a.75.75 0 01-1.14-.96l2.68-3.17a5.25 5.25 0 001.14-3.27v-6.9a1.25 1.25 0 10-2.5 0v5a.75.75 0 01-1.5 0v-5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M25.75 21.42a1.12 1.12 0 00-1.6.38l-1.52 2.28a5.25 5.25 0 00-.88 2.92v4a.75.75 0 01-1.5 0v-4c0-1.33.4-2.64 1.14-3.75l1.5-2.27a2.62 2.62 0 013.7-.81m-.84 1.25l.42-.63-.42.63zm.42-.63l-.42.63c.46.3.63.9.4 1.4l-1.8 2.97a.75.75 0 001.3.78l1.8-3a2.62 2.62 0 00-.86-3.4"
        clipRule="evenodd"
    />
</svg>;

export default Index;
