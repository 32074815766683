import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) =>   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.24 230.24">
<path fill={fill} fillRule="evenodd"
  d="M115.12 230.24a115.12 115.12 0 11115.12-115.12 115.25 115.25 0 01-115.12 115.12zm0-223A107.88 107.88 0 10223 115.12 108 108 0 00115.12 7.24z"

/>
<path fill={fill} fillRule="evenodd"
  d="M129.08 144.7l58.92 5.63-57.28-33.08a15.18 15.18 0 00-3.88-12l7.08-16-24.58-53.8v66.14a15.54 15.54 0 00-8.39 9.32l-17.43 1.89L49.17 161l57.28-33.07.26.18-8.65 96.35h5.25l2-22.17h9.8v-5.22h-9.33l2.13-24.07h9.94v-5.2h-9.47l3.35-37.36a14.28 14.28 0 007 .08l.58.8 6.84 76.36h-9.26v5.21h9.74l1 11.58h5.23l-7.62-85zm-61.76-.19l19.05-26.76 13.82-1.49a15.29 15.29 0 002.55 7.79zm47.22-85.09l13.64 29.88-5.62 12.7a16.22 16.22 0 00-7.22-1.71h-.8zm.93 66.27a10.09 10.09 0 1110.1-10.09 10.09 10.09 0 01-10.1 10.09zm8.79 2.46a15.65 15.65 0 004.94-5.74l35.41 20.45-32.69-3.13-7.56-10.32z"

/>
</svg>;

export default Index;
