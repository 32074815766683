import React from 'react'

const Index = ({ fill = 'white', ...props }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
        fill={fill}
        fillRule="evenodd"
        d="M19 6.4L17.6 5 12 10.6 6.4 5 5 6.4l5.6 5.6L5 17.6 6.4 19l5.6-5.6 5.6 5.6 1.4-1.4-5.6-5.6L19 6.4z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
