import gsap from 'gsap/dist/gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { pushEvent } from './tracking';

gsap.registerPlugin(ScrollToPlugin);

class Browser {
	static scrollTo(target: number | string = 0) {
		const scrollToTarget: any =
			typeof target === 'number' ? target : document.querySelector(target);

		gsap.to(window, {
			duration: 0.75,
			scrollTo: scrollToTarget,
			ease: 'power3.out',
			onComplete: () => {
				if (typeof target !== 'number') {
					scrollToTarget.focus();

					if (document.activeElement !== scrollToTarget) {
						scrollToTarget.setAttribute('tabindex', '-1');
						scrollToTarget.focus();
					}
				}
			},
		});
	}

	static handleClick = (event: any, link: string | undefined, callback?: any | undefined) => {
		pushEvent(event);

		if (callback) {
			callback(event);
		}

		if (link) {
			const isExternalUrl = link.includes('http');

			if (!isExternalUrl && link.startsWith('#')) {
				event.preventDefault();
				Browser.scrollTo(link);
			}
		}
	};
}

export default Browser;
