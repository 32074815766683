import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M11.3 10a4.7 4.7 0 119.4 0 4.7 4.7 0 01-9.4 0zM16 6.7a3.3 3.3 0 100 6.6 3.3 3.3 0 000-6.6z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M16 1.8A8.3 8.3 0 007.7 10c0 1 .4 2.2 1 3.8a99.7 99.7 0 007.2 12 99.7 99.7 0 007.3-12c.7-1.6 1-2.9 1-3.8 0-4.6-3.6-8.3-8.2-8.3zM6.2 10a9.7 9.7 0 1119.6 0 12 12 0 01-1.2 4.4A101.9 101.9 0 0117 26.9a1.3 1.3 0 01-2 0 101.9 101.9 0 01-7.6-12.5A12 12 0 016.2 10z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M23.2 23.6c.1-.4.5-.7 1-.6 1.8.3 3.4.8 4.5 1.5.6.3 1 .6 1.4 1 .4.4.6 1 .6 1.5 0 1-.6 1.7-1.4 2.2-.8.5-1.9 1-3.2 1.3-2.6.8-6.2 1.3-10.1 1.3-4 0-7.5-.5-10.1-1.3-1.3-.3-2.4-.8-3.2-1.3-.8-.5-1.5-1.3-1.5-2.2 0-.6.3-1 .7-1.5.3-.4.8-.7 1.4-1A17 17 0 017.9 23a.7.7 0 11.2 1.5c-1.7.3-3.1.8-4.1 1.3-.5.2-.8.5-1 .7l-.3.5c0 .2.2.5.8 1 .6.4 1.6.8 2.8 1.1a37 37 0 009.7 1.1 37 37 0 009.7-1c1.2-.4 2.2-.8 2.8-1.3.6-.4.8-.7.8-.9 0-.1 0-.3-.3-.5-.2-.2-.5-.5-1-.7-1-.5-2.4-1-4.2-1.4a.8.8 0 01-.6-.8z" clipRule="evenodd" />
</svg>;

export default Index;
