import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M8.25 14.5c0-1.24 1-2.25 2.25-2.25h11c1.24 0 2.25 1 2.25 2.25v7c0 1.24-1 2.25-2.25 2.25h-11c-1.24 0-2.25-1-2.25-2.25v-7zm2.25-.75a.75.75 0 00-.75.75v7c0 .41.34.75.75.75h11c.41 0 .75-.34.75-.75v-7a.75.75 0 00-.75-.75h-11z"
        clipRule="evenodd"
    />
    <path fill={fill} d="M16 16.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z" />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M16 5.75A4.25 4.25 0 0011.75 10v3a.75.75 0 11-1.5 0v-3a5.75 5.75 0 0111.5 0v3a.75.75 0 11-1.5 0v-3c0-2.35-1.9-4.25-4.25-4.25z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.25 1C.25.59.59.25 1 .25h30c.41 0 .75.34.75.75v13c0 4.85-1.59 8.7-4.36 11.63-2.77 2.91-6.66 4.88-11.2 6.1a.75.75 0 01-.38 0c-4.54-1.22-8.43-3.18-11.2-6.1C1.85 22.7.26 18.85.26 14V1zm1.5.75V14c0 4.5 1.46 7.96 3.96 10.6 2.47 2.61 6.01 4.46 10.29 5.62 4.28-1.17 7.82-3.01 10.3-5.62 2.5-2.64 3.95-6.1 3.95-10.6V1.75H1.75z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
