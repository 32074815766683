import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 80" xmlSpace="preserve">
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path
          d="M12.75 14.25v6.268a2.732 2.732 0 01-2.732 2.732H3.483A2.733 2.733 0 01.75 20.518V4.983A2.734 2.734 0 013.483 2.25H6M.75 18.75h12"
          transform="scale(3.33333)"
        ></path>
        <path
          d="M20.25.75H12a3 3 0 00-3 3v4.5a3 3 0 003 3h3.75V15l3.75-3.75h.75a3 3 0 003-3v-4.5a3 3 0 00-3-3zM12.375 5.625h0"
          transform="scale(3.33333)"
        ></path>
        <path
          d="M12.375 5.625A.375.375 0 1012.75 6a.375.375 0 00-.375-.375M16.125 5.625h0M16.125 5.625A.375.375 0 1016.5 6a.375.375 0 00-.375-.375M19.875 5.625h0M19.875 5.625A.375.375 0 1020.25 6a.375.375 0 00-.375-.375"
          transform="scale(3.33333)"
        ></path>
      </g>
    </svg>;

export default Index;
