import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M2 19.75a.25.25 0 00-.25.25v11a.75.75 0 01-1.5 0V20c0-.97.78-1.75 1.75-1.75h4a.75.75 0 010 1.5H2zM4.75 4.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zM9 1.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM7 11.75A3.25 3.25 0 003.75 15a.75.75 0 01-1.5 0A4.75 4.75 0 017 10.25h4A4.75 4.75 0 0115.75 15a.75.75 0 01-1.5 0c0-1.8-1.46-3.25-3.25-3.25H7zM6.25 26a5.75 5.75 0 1111.5 0 5.75 5.75 0 01-11.5 0zM12 21.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zM31.73 20.95c.08.4-.18.8-.59.88a4.25 4.25 0 000 8.33.75.75 0 01-.3 1.47 5.75 5.75 0 010-11.26c.4-.09.8.17.89.58zM17.25 15c0-.41.34-.75.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M20.35 18.71l-6.54 6.54h5.87l6.96-7.27-6.3.73zM28.62 17l-.09-.75-8.62 1a.75.75 0 00-.44.22l-8 8a.75.75 0 00.53 1.28h8c.2 0 .4-.08.54-.23l8.62-9-.54-.52z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M26.5 12.55c.13-.2.36-.32.61-.32l2.9.02a.75.75 0 11-.02 1.5l-1.8-.01 3.51 9.33a.75.75 0 01-1.4.53l-3.9-10.36a.75.75 0 01.1-.7z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
