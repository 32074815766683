import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <path fill={fill} fillRule="evenodd" d="M5.827 10.249h20.346c.729 0 1.427.29 1.943.806m0 0l2.829 2.828c.515.516.805 1.216.805 1.945v11.17a.75.75 0 01-1.5 0v-11.17c0-.332-.131-.65-.366-.884l-2.829-2.828a1.25 1.25 0 00-.883-.367H5.828a1.25 1.25 0 00-.883.366l-2.83 2.829a1.25 1.25 0 00-.365.884v11.17a.75.75 0 01-1.5 0v-11.17c0-.73.29-1.429.805-1.945l2.829-2.828a2.75 2.75 0 011.943-.806" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M5.25 14.999a.75.75 0 01.75-.75h20a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM16 5.75A4.25 4.25 0 0011.75 10v.999a.75.75 0 01-1.5 0V10a5.75 5.75 0 0111.5 0v1a.75.75 0 01-1.5 0v-1A4.25 4.25 0 0016 5.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M11 14.249a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0v-12a.75.75 0 01.75-.75zM21 14.249a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0v-12a.75.75 0 01.75-.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M8.359 18.61a.75.75 0 011.03-.253A13.084 13.084 0 0016 20.25a13.084 13.084 0 006.612-1.892.75.75 0 11.777 1.283 14.585 14.585 0 01-7.398 2.109 14.585 14.585 0 01-7.38-2.109.75.75 0 01-.252-1.03z" clipRule="evenodd"/>
</svg>;

export default Index;
