import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M3 1.75c-.7 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h6c.16 0 .32.05.45.15l2.8 2.1V11c0-.41.33-.75.75-.75h2c.69 0 1.25-.56 1.25-1.25V3c0-.69-.56-1.25-1.25-1.25H3zM.25 3A2.75 2.75 0 013 .25h12A2.75 2.75 0 0117.75 3v6A2.75 2.75 0 0115 11.75h-1.25V14a.75.75 0 01-1.2.6l-3.8-2.85H3A2.75 2.75 0 01.25 9V3zM14.25 20A2.75 2.75 0 0117 17.25h12A2.75 2.75 0 0131.75 20v6A2.75 2.75 0 0129 28.75h-5.75l-3.8 2.85a.75.75 0 01-1.2-.6v-2.25H17A2.75 2.75 0 0114.25 26v-6zM17 18.75c-.7 0-1.25.56-1.25 1.25v6c0 .69.56 1.25 1.25 1.25h2c.41 0 .75.34.75.75v1.5l2.8-2.1c.13-.1.29-.15.45-.15h6c.69 0 1.25-.56 1.25-1.25v-6c0-.69-.56-1.25-1.25-1.25H17zM2.38 22.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm4.25-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.3 30.75a6.72 6.72 0 0112.67 0 .75.75 0 11-1.41.5 5.22 5.22 0 00-9.85 0 .75.75 0 01-1.42-.5zM21.12 5.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm4.25-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M19.02 13.75a6.72 6.72 0 0112.69 0 .75.75 0 11-1.42.5 5.22 5.22 0 00-9.85 0 .75.75 0 01-1.42-.5zM18.25 23c0-.41.33-.75.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M24.47 20.47c.3-.3.77-.3 1.06 0l2 2c.3.3.3.77 0 1.06l-2 2a.75.75 0 01-1.06-1.06L25.94 23l-1.47-1.47a.75.75 0 010-1.06zM4.25 6c0-.41.33-.75.75-.75h8a.75.75 0 010 1.5H5A.75.75 0 014.25 6z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M7.53 3.47c.3.3.3.77 0 1.06L6.06 6l1.47 1.47a.75.75 0 01-1.06 1.06l-2-2a.75.75 0 010-1.06l2-2c.3-.3.77-.3 1.06 0z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
