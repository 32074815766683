import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
    <defs/>
    <path fill={fill} fillRule="evenodd" d="M4.5 10a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0zM14 3.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM14 20.5a1.5 1.5 0 011.5 1.5v16a1.5 1.5 0 01-3 0V22a1.5 1.5 0 011.5-1.5z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M.5 34c0-7.456 6.044-13.5 13.5-13.5S27.5 26.544 27.5 34v8a1.5 1.5 0 01-1.5 1.5h-2.642l-1.865 18.65A1.5 1.5 0 0120 63.5H8a1.5 1.5 0 01-1.493-1.35L4.643 43.5H2A1.5 1.5 0 01.5 42v-8zM14 23.5C8.201 23.5 3.5 28.201 3.5 34v6.5H6a1.5 1.5 0 011.493 1.35L9.357 60.5h9.285l1.865-18.65A1.5 1.5 0 0122 40.5h2.5V34c0-5.799-4.701-10.5-10.5-10.5zM28.5 2A1.5 1.5 0 0130 .5h32a1.5 1.5 0 010 3H30A1.5 1.5 0 0128.5 2zM32.5 44a1.5 1.5 0 011.5-1.5h28a1.5 1.5 0 010 3H34a1.5 1.5 0 01-1.5-1.5zM45.191 8.204a20.647 20.647 0 014.854.575 1.5 1.5 0 01-.703 2.917 17.643 17.643 0 00-4.15-.492c-3.313 0-6.549 1.085-9.062 2.801-2.53 1.728-4.19 3.993-4.616 6.267M45.192 8.204c-3.943 0-7.764 1.283-10.753 3.324-2.973 2.03-5.26 4.911-5.873 8.194-.688 3.695.574 6.833 2.463 9.155 1.865 2.291 4.398 3.87 6.514 4.549h.003a6.996 6.996 0 008.249-3.292 1.5 1.5 0 00-2.628-1.447 3.996 3.996 0 01-4.71 1.882c-1.576-.506-3.613-1.758-5.1-3.585-1.463-1.798-2.334-4.067-1.842-6.711M54.442 26.032a1.5 1.5 0 01.671 2.012l-5.312 10.627a1.5 1.5 0 01-2.683-1.342l5.312-10.626a1.5 1.5 0 012.012-.671z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M56.095 13.245a1.5 1.5 0 011.671.14 9.735 9.735 0 012.088 12.856 5.364 5.364 0 01-9.547-4.891c1.21-2.42 2.77-3.417 3.819-4.085.128-.082.248-.159.36-.233.496-.33.663-.508.758-.696.093-.187.219-.603.094-1.594a1.5 1.5 0 01.757-1.497zm1.632 4.785c-.457.715-1.075 1.164-1.58 1.5-.137.091-.273.179-.409.266-.986.635-1.919 1.236-2.748 2.896l-.001.002a2.364 2.364 0 104.225 2.121 1.52 1.52 0 01.086-.15 6.734 6.734 0 00.427-6.635zM37.373 21.706a1.5 1.5 0 011.5-1.5 2.5 2.5 0 012.5 2.5 1.5 1.5 0 01-2.936.436 1.5 1.5 0 01-1.064-1.436z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M39.303 23.142a1.5 1.5 0 00-.436-2.936 2.5 2.5 0 00-2.5 2.5 1.5 1.5 0 002.936.436z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M39.309 22.27a1.5 1.5 0 00-2.936.436 2.5 2.5 0 002.5 2.5 1.5 1.5 0 00.436-2.935z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M39.873 21.206a1.5 1.5 0 011.5 1.5 2.5 2.5 0 01-2.5 2.5 1.5 1.5 0 01-.436-2.935 1.5 1.5 0 011.436-1.065zM45.373 15.707a1.5 1.5 0 011.5-1.5 2.5 2.5 0 012.5 2.5 1.5 1.5 0 01-2.936.435 1.5 1.5 0 01-1.064-1.435z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M47.303 17.142a1.5 1.5 0 00-.436-2.935 2.5 2.5 0 00-2.5 2.5 1.5 1.5 0 002.936.435z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M47.309 16.27a1.5 1.5 0 00-2.936.436 2.5 2.5 0 002.5 2.5 1.5 1.5 0 00.436-2.935z" clipRule="evenodd"/>
    <path fill={fill} fillRule="evenodd" d="M47.873 15.207a1.5 1.5 0 011.5 1.5 2.5 2.5 0 01-2.5 2.5 1.5 1.5 0 01-.436-2.936 1.5 1.5 0 011.436-1.065z" clipRule="evenodd"/>
</svg>;

export default Index;
