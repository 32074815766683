import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path fill={fill} d="M20 63.5a3.6 3.6 0 01-3-1.7 3.5 3.5 0 010-3.5l11.5-18.6V28a3.5 3.5 0 013.5-3.5h4a3.5 3.5 0 013.5 3.5V39.7L51 58.3a3.5 3.5 0 01-3 5.2H20zm12-36a.5.5 0 00-.5.5v11.4c0 .7-.2 1.3-.5 1.9L19.5 59.8a.4.4 0 000 .5.5.5 0 00.4.2H48a.5.5 0 00.4-.2.5.5 0 000-.5L37 41.3c-.4-.6-.5-1.2-.5-1.9V28a.5.5 0 00-.5-.5h-4z" />
    <path fill={fill} fillRule="evenodd" d="M23.6 48a1.5 1.5 0 011.5-1.5h17.8a1.5 1.5 0 110 3H25.1a1.5 1.5 0 01-1.5-1.5zM6 3.5A2.5 2.5 0 003.5 6v24A2.5 2.5 0 006 32.5h16a1.5 1.5 0 110 3H6A5.5 5.5 0 01.5 30V6A5.5 5.5 0 016 .5h32A5.5 5.5 0 0143.5 6a1.5 1.5 0 11-3 0A2.5 2.5 0 0038 3.5H6z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M22.7 32.7a1.5 1.5 0 01.6 2l-4 8a1.5 1.5 0 11-2.6-1.4l4-8a1.5 1.5 0 012-.6z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M12.5 42a1.5 1.5 0 011.5-1.5h8a1.5 1.5 0 110 3h-8a1.5 1.5 0 01-1.5-1.5zM.5 24A1.5 1.5 0 012 22.5h20a1.5 1.5 0 110 3H2A1.5 1.5 0 01.5 24zM52.5 20a1.5 1.5 0 011.5-1.5h8a1.5 1.5 0 011.5 1.5v27.3a4.2 4.2 0 01-4.2 4.2h-2.6a4.2 4.2 0 01-4.2-4.2V20zm3 1.5v25.8a1.2 1.2 0 001.2 1.2h2.6a1.2 1.2 0 001.2-1.2V21.5h-5zM58 .5A1.5 1.5 0 0159.5 2v8a1.5 1.5 0 11-3 0V2A1.5 1.5 0 0158 .5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M52.5 6A1.5 1.5 0 0154 4.5h8a1.5 1.5 0 010 3h-8A1.5 1.5 0 0152.5 6zM44 12.5a1.5 1.5 0 011.5 1.5v8a1.5 1.5 0 11-3 0v-8a1.5 1.5 0 011.5-1.5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M38.5 18a1.5 1.5 0 011.5-1.5h8a1.5 1.5 0 110 3h-8a1.5 1.5 0 01-1.5-1.5z" clipRule="evenodd" />
</svg>;

export default Index;
