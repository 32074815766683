import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg viewBox="0 0 58 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M36.4052 6.42514C36.6108 5.62262 37.428 5.1387 38.2305 5.34427C48.2672 7.91525 55.7465 16.3097 57.147 26.5754C58.5079 36.5508 53.8653 46.3613 45.3609 51.6478V61.9974C45.3609 62.8258 44.6894 63.4974 43.8609 63.4974C43.0325 63.4974 42.3609 62.8258 42.3609 61.9974V50.7974C42.3609 50.2606 42.6478 49.7647 43.1131 49.4971C51.0366 44.94 55.41 36.0375 54.1745 26.9809C52.939 17.9243 46.3406 10.5186 37.4861 8.25044C36.6835 8.04487 36.1996 7.22766 36.4052 6.42514Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M20.3591 30C20.3591 23.6487 25.5079 18.5 31.8591 18.5C38.2104 18.5 43.3591 23.6487 43.3591 30C43.3591 36.3513 38.2104 41.5 31.8591 41.5C25.5079 41.5 20.3591 36.3513 20.3591 30ZM31.8591 21.5C27.1647 21.5 23.3591 25.3056 23.3591 30C23.3591 34.6944 27.1647 38.5 31.8591 38.5C36.5536 38.5 40.3591 34.6944 40.3591 30C40.3591 25.3056 36.5536 21.5 31.8591 21.5Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M27.8591 3.5C27.583 3.5 27.3591 3.72386 27.3591 4V22C27.3591 22.8284 26.6876 23.5 25.8591 23.5C25.0307 23.5 24.3591 22.8284 24.3591 22V4C24.3591 2.067 25.9261 0.5 27.8591 0.5H35.8591C37.7921 0.5 39.3591 2.067 39.3591 4V22C39.3591 22.8284 38.6876 23.5 37.8591 23.5C37.0307 23.5 36.3591 22.8284 36.3591 22V4C36.3591 3.72386 36.1353 3.5 35.8591 3.5H27.8591Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M27.3283 6.26384C27.4933 7.07566 26.969 7.86756 26.1572 8.0326C16.329 10.0307 9.36104 17.4643 9.36104 26.0293V28.0293C9.36104 28.3256 9.27333 28.6152 9.10897 28.8616L4.25589 36.1386C4.25581 36.1387 4.25598 36.1384 4.25589 36.1386C3.80533 36.8149 3.71135 37.6684 4.00434 38.4264C4.29739 39.1845 4.94073 39.7529 5.72923 39.9502L8.22484 40.5741C9.02854 40.7751 9.51718 41.5895 9.31625 42.3931C9.11533 43.1968 8.30093 43.6855 7.49724 43.4846L5.00124 42.8606C3.26654 42.4264 1.85082 41.1759 1.20611 39.508C0.561397 37.84 0.767805 35.9628 1.75952 34.4748L6.36104 27.575V26.0293C6.36104 15.7037 14.6891 7.30269 25.5595 5.09274C26.3714 4.9277 27.1633 5.45202 27.3283 6.26384Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M20.831 54.8852C21.1671 55.1702 21.3609 55.5886 21.3609 56.0293V62C21.3609 62.8284 20.6894 63.5 19.8609 63.5C19.0325 63.5 18.3609 62.8284 18.3609 62V57.7986L17.4236 57.954C16.1127 58.176 14.7697 58.1185 13.4825 57.7855C12.6805 57.578 12.1986 56.7596 12.4061 55.9576C12.6136 55.1556 13.432 54.6736 14.234 54.8811C15.1128 55.1085 16.0298 55.1476 16.9247 54.9958L16.9302 54.9948L16.9302 54.9949L19.6155 54.5495C20.0503 54.4774 20.4948 54.6002 20.831 54.8852Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M31.8591 28.5C32.6876 28.5 33.3591 29.1716 33.3591 30C33.3591 41.8741 23.7333 51.5 11.8591 51.5H7.85913C7.0307 51.5 6.35913 50.8284 6.35913 50C6.35913 49.1716 7.0307 48.5 7.85913 48.5H11.8591C22.0764 48.5 30.3591 40.2173 30.3591 30C30.3591 29.1716 31.0307 28.5 31.8591 28.5Z" />
</svg>;

export default Index;
