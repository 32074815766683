import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M27 7.9c.4 0 .8.3.8.7V13c0 .4.2.9.7 1 1.7.8 2.6 2.6 2.1 4.4a3.8 3.8 0 01-4.1 2.6 3.7 3.7 0 01-3.3-3.6.8.8 0 011.6 0c0 1 .8 2 1.9 2 1.1.2 2.2-.4 2.5-1.5.3-1-.3-2-1.3-2.5-1-.4-1.7-1.3-1.6-2.4V8.6c0-.4.3-.7.7-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M.3 8.6c0-.4.3-.7.7-.7h30a.8.8 0 010 1.5H1a.8.8 0 01-.8-.8z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M13 1.8L4.5 9.2a.7.7 0 01-1-1.1L12 .6c.5-.4 1.3-.5 2-.2L27.4 8a.7.7 0 11-.8 1.3L13.3 1.7H13z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M6 7.9c.4 0 .8.3.8.7v21.2a.8.8 0 01-1.5 0V8.6c0-.4.3-.7.7-.7zM12 7.9c.4 0 .8.3.8.7v21.2a.8.8 0 01-1.6 0V8.6c0-.4.4-.7.8-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M.3 29.8c0-.5.3-.8.7-.8h16a.8.8 0 010 1.5H1a.8.8 0 01-.8-.7zM5.5 10c.4-.3.8-.2 1 .1l6 7.7a.7.7 0 11-1 .9l-6-7.7a.7.7 0 010-1zM12.5 19.6c.3.2.3.7 0 1l-6 7.7a.7.7 0 11-1-1l6-7.6c.2-.3.6-.4 1-.1z" clipRule="evenodd" />
</svg>;

export default Index;
