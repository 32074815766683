import React from 'react'

const Index = ({ fill = 'white' }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" width="100%" height="100%">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M18 5H3.8l3.6-3.6L6 0 0 6l6 6 1.4-1.4L3.8 7H18V5z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
