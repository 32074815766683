import React from 'react'

const Index = ({ fill = 'white' }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M0 7h14.2l-3.6 3.6L12 12l6-6-6-6-1.4 1.4L14.2 5H0v2z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
