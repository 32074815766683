import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
    <path fill={fill} fillRule="evenodd" d="M10 17.75a1.25 1.25 0 100 2.5h12a1.25 1.25 0 100-2.5H10zM7.25 19A2.75 2.75 0 0110 16.25h12a2.75 2.75 0 110 5.5H10A2.75 2.75 0 017.25 19z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M16 20.25a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V21a.75.75 0 01.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M15.973 25.75a8.127 8.127 0 00-7.98 5.492.75.75 0 11-1.42-.485A9.627 9.627 0 0116 24.25a9.63 9.63 0 019.425 6.508.75.75 0 11-1.42.486 8.13 8.13 0 00-8.032-5.494zM4 12.25a.75.75 0 01.75.75v4A3.25 3.25 0 008 20.25h2a.75.75 0 010 1.5H8A4.75 4.75 0 013.25 17v-4a.75.75 0 01.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M1.25 13a.75.75 0 01.75-.75h4a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75zM28 12.25a.75.75 0 01.75.75v4A4.75 4.75 0 0124 21.75h-2a.75.75 0 010-1.5h2A3.25 3.25 0 0027.25 17v-4a.75.75 0 01.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M25.25 13a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM9.25 7a6.75 6.75 0 0113.5 0v10a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V7zM16 1.75A5.25 5.25 0 0010.75 7v9.25h10.5V7c0-2.9-2.35-5.25-5.25-5.25z" clipRule="evenodd" />
</svg>;

export default Index;
