import React from 'react';
import colors from '../../../styles/settings/colors';

const Index = ({ fill = colors.base.blue }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="48" width="48">
		<g>
			<path
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M2 6v13.5"
			/>
			<path
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M22 6v13.5"
			/>
			<path
				d="M5.5 16.5H12v7h0-6.5A.5.5 0 0 1 5 23v-6a.5.5 0 0 1 .5-.5Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12 16.5h6.5a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5H12h0v-7h0Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9 9.5h6a.5.5 0 0 1 .5.5v6.5h0-7 0V10a.5.5 0 0 1 .5-.5Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M10.5 9.5h3V12a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V9.5h0Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7 16.5h3V19a.5.5 0 0 1-.5.5h-2A.5.5 0 0 1 7 19v-2.5h0Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M14 16.5h3V19a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.5h0Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M22.5 6a.5.5 0 0 0 .5-.5V4.36a.5.5 0 0 0-.34-.47L12.3.55a.88.88 0 0 0-.6 0L1.35 3.89a.5.5 0 0 0-.35.48V5.5a.5.5 0 0 0 .5.5Z"
				fill="none"
				stroke={fill}
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</g>
	</svg>
);

export default Index;
