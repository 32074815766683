import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <path fill={fill} fillRule="evenodd" d="M2.063 10.25a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM5.813 8a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM1.25 26.75A.75.75 0 012 26h4a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75zM12.281 20.75a.75.75 0 01.75-.75h14a.75.75 0 110 1.5h-14a.75.75 0 01-.75-.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M20 20.25a.75.75 0 01.75.75v10a.75.75 0 01-1.5 0V21a.75.75 0 01.75-.75zM30.295 9.31a.75.75 0 01.394.985l-3 7a.75.75 0 01-.689.455h-8a.75.75 0 010-1.5h7.506l2.805-6.545a.75.75 0 01.984-.394zM19 8.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM22.004 6.995a4.253 4.253 0 00-6.008 0 .75.75 0 11-1.059-1.062 5.753 5.753 0 018.126 0 .75.75 0 11-1.06 1.062z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M19 1.75a8.25 8.25 0 00-5.834 2.416.75.75 0 01-1.06-1.06 9.75 9.75 0 0113.788 0 .75.75 0 11-1.06 1.06A8.25 8.25 0 0019 1.75zM5 26.375a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0v-4a.75.75 0 01.75-.75z" clipRule="evenodd"/>
  <path fill={fill} d="M12.75 31.5c-1.05 0-2-.6-2.45-1.5l-.9-1.8c-.2-.45-.65-.7-1.1-.7H5.75C4.25 27.5 3 26.25 3 24.75v-8c0-1 .55-1.95 1.45-2.45.9-.5 2-.45 2.8.15l1.5 1c.55.35 1.15.55 1.8.55h2.2c1.5 0 2.75 1.25 2.75 2.75s-1.25 2.75-2.75 2.75h-2.2c-.7 0-1.4-.1-2.05-.25V22c2.5.05 4.7 1.5 5.8 3.75l.9 1.8c.4.85.4 1.85-.1 2.7-.5.75-1.4 1.25-2.35 1.25zm0-1.5c.45 0 .85-.25 1.05-.6.25-.35.25-.85.05-1.2L13 26.4c-.9-1.8-2.7-2.9-4.7-2.9h-.55c-.4 0-.75-.35-.75-.75v-2.5c0-.25.1-.5.3-.6.2-.15.5-.2.7-.1.8.3 1.7.45 2.55.45h2.2c.7 0 1.25-.55 1.25-1.25s-.55-1.25-1.25-1.25h-2.2c-.95 0-1.85-.25-2.6-.8l-1.5-1c-.4-.25-.9-.25-1.3-.05s-.65.65-.65 1.1v8c0 .7.55 1.25 1.25 1.25H8.3c1.05 0 2 .6 2.45 1.5l.9 1.8c.2.45.6.7 1.1.7z"/>
</svg>;

export default Index;
