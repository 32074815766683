import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M21.23 1.05a2.75 2.75 0 013.89 0l5.82 5.83a2.75 2.75 0 01-3.89 3.9l-5.82-5.84a2.75 2.75 0 010-3.89zm1.06 1.07a1.25 1.25 0 011.77 0l5.82 5.82a1.25 1.25 0 01-1.76 1.77l-5.83-5.83a1.25 1.25 0 010-1.76z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M15.57 1.05a2.75 2.75 0 013.9 0l11.47 11.49a2.75 2.75 0 01-3.88 3.9L15.57 4.93a2.75 2.75 0 010-3.89zm1.95.7a1.25 1.25 0 00-.89 2.14l11.49 11.48a1.25 1.25 0 001.76-1.77L18.4 2.1a1.25 1.25 0 00-.88-.36zM3 21.92a1.25 1.25 0 00-.88 2.13l5.82 5.83a1.25 1.25 0 001.77-1.77l-5.83-5.82a1.25 1.25 0 00-.88-.37zm-1.94-.7a2.75 2.75 0 013.89 0l5.83 5.84a2.75 2.75 0 01-3.89 3.9v-.02l-5.83-5.82a2.75 2.75 0 010-3.9z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M3.88 16.63a1.25 1.25 0 10-1.77 1.77L13.6 29.88a1.25 1.25 0 001.77-1.76L3.88 16.62zm-2.82-1.06a2.75 2.75 0 013.88 0l11.49 11.48a2.75 2.75 0 01-3.9 3.9m0 0L1.07 19.45a2.75 2.75 0 010-3.9" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M8.21 20.96a.75.75 0 010-1.06L19.9 8.2c.3-.3.77-.3 1.06 0l2.83 2.83c.29.3.29.77 0 1.06L12.1 23.8c-.3.29-.77.29-1.06 0l-2.83-2.83zm1.6-.53l1.76 1.77L22.2 11.57 20.43 9.8 9.8 20.43z" clipRule="evenodd"/>
</svg>;

export default Index;
