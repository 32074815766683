import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path fillRule="evenodd" clipRule="evenodd" d="M0.74742 23.2235C2.27129 14.2584 10.4077 7.95435 19.4692 8.71784C28.5308 9.48134 35.4976 17.0579 35.5 26.1516C35.5019 33.74 30.622 40.4155 23.5 42.7696V58.0107C23.5 61.0482 21.0375 63.5107 18 63.5107C14.9624 63.5107 12.5 61.0482 12.5 58.0107V42.7699C4.34889 40.0785 -0.710489 31.8006 0.74742 23.2235ZM19.2174 11.7073C11.7092 11.0746 4.96763 16.298 3.705 23.7262C2.44236 31.1545 7.07945 38.3119 14.375 40.1956C15.0372 40.3666 15.5 40.964 15.5 41.648V58.0107C15.5 59.3914 16.6192 60.5107 18 60.5107C19.3807 60.5107 20.5 59.3914 20.5 58.0107V41.648C20.5 40.964 20.9627 40.3666 21.625 40.1956C28.0283 38.5423 32.5016 32.7657 32.5 26.1524C32.498 18.6176 26.7255 12.3399 19.2174 11.7073Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2283 10.9798C32.1168 7.01636 40.8112 8.19238 46.3993 13.8435C51.7105 19.2146 52.9479 27.3465 49.5881 34.0152L61.9061 46.3231C63.9904 48.4812 63.9605 51.9117 61.8389 54.0332C59.7174 56.1548 56.287 56.1846 54.1289 54.1002L54.1103 54.0822L41.8183 41.7924C40.111 42.6508 38.2717 43.2238 36.3735 43.4859C35.5528 43.5992 34.7957 43.0257 34.6824 42.2051C34.5691 41.3845 35.1426 40.6274 35.9632 40.5141C37.8559 40.2528 39.6778 39.6181 41.3232 38.6469C41.9124 38.2991 42.6623 38.3941 43.1462 38.8779L56.2214 51.9504C57.2028 52.8897 58.756 52.8735 59.7176 51.9119C60.6791 50.9504 60.6954 49.3974 59.7563 48.416L46.6734 35.3437C46.1892 34.8599 46.0939 34.1095 46.442 33.52C49.7898 27.8496 48.8962 20.6352 44.2661 15.9529C39.636 11.2705 32.4321 10.2961 26.7244 13.5801C26.0063 13.9933 25.0893 13.7461 24.6762 13.028C24.263 12.31 24.5102 11.393 25.2283 10.9798Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 24.152C12.5 21.1144 14.9624 18.652 18 18.652C21.0376 18.652 23.5 21.1144 23.5 24.152C23.5 27.1895 21.0376 29.652 18 29.652C14.9624 29.652 12.5 27.1895 12.5 24.152ZM18 21.652C16.6193 21.652 15.5 22.7713 15.5 24.152C15.5 25.5327 16.6193 26.652 18 26.652C19.3807 26.652 20.5 25.5327 20.5 24.152C20.5 22.7713 19.3807 21.652 18 21.652Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 3.5C21.5147 3.5 19.5 5.51472 19.5 8V20.152C19.5 20.9804 18.8284 21.652 18 21.652C17.1716 21.652 16.5 20.9804 16.5 20.152V8C16.5 3.85786 19.8579 0.5 24 0.5C28.1421 0.5 31.5 3.85786 31.5 8V10.6373C31.5 11.4658 30.8284 12.1373 30 12.1373C29.1716 12.1373 28.5 11.4658 28.5 10.6373V8C28.5 5.51472 26.4853 3.5 24 3.5Z" fill={fill} />
</svg>;

export default Index;
