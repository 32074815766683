import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M8.21 1c0-.41.34-.75.75-.75h14c.42 0 .75.34.75.75v7a7.75 7.75 0 01-15.5 0V1zm1.5.75V8a6.25 6.25 0 1012.5 0V1.75H9.71z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M.21 1C.21.59.55.25.96.25h30a.75.75 0 010 1.5h-30A.75.75 0 01.21 1zM8.21 7c0-.41.34-.75.75-.75h14a.75.75 0 010 1.5h-14A.75.75 0 018.21 7z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M2.96.25c.42 0 .75.34.75.75v10a.75.75 0 01-1.5 0V1c0-.41.34-.75.75-.75zM8.6 19.28a.75.75 0 011.06-.13l6.3 4.9 6.3-4.9a.75.75 0 11.93 1.18l-6.77 5.26a.75.75 0 01-.92 0l-6.76-5.26a.75.75 0 01-.13-1.05z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M1.25 30.95a14.75 14.75 0 0129.43 0 .75.75 0 11-1.5.1 13.25 13.25 0 00-26.43 0 .75.75 0 11-1.5-.1zM9.05 8.25c.4 0 .74.35.73.76L9.03 9l.75.01v.02a3.79 3.79 0 010 .24l-.08.65a11.1 11.1 0 01-.5 2.07 7.96 7.96 0 01-3.9 4.68.75.75 0 11-.67-1.34 6.47 6.47 0 003.16-3.82 9.6 9.6 0 00.5-2.48v-.04c0-.41.34-.75.76-.74zM22.88 8.25c.42 0 .76.33.76.74L22.9 9l.75-.01v.04a4.66 4.66 0 00.07.68c.06.46.19 1.1.43 1.8a6.47 6.47 0 003.16 3.82.75.75 0 11-.67 1.34A7.97 7.97 0 0122.72 12a11.11 11.11 0 01-.58-2.9V9.02L22.9 9l-.75.01c0-.41.33-.75.74-.76z" clipRule="evenodd"/>
</svg>;

export default Index;
