import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 64">
    <path fill={fill} fillRule="evenodd" d="M24.16 33a6.5 6.5 0 0113 0v14.1l8.56 1.91a9.5 9.5 0 017.4 10.13l-.26 3a1.5 1.5 0 01-3-.27l.28-3a6.5 6.5 0 00-5.07-6.93l-9.73-2.16a1.5 1.5 0 01-1.18-1.47V33a3.5 3.5 0 10-7 0v24.53a1.5 1.5 0 01-2.4 1.2l-3.93-2.94a2.59 2.59 0 00-3.62 3.62l1.26 1.69a1.5 1.5 0 01-2.4 1.8l-1.26-1.69a5.59 5.59 0 017.82-7.82l1.53 1.15V33zM21.17 9a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0zm8.5-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M22.83 18.46a12.87 12.87 0 0113.74.05 1.5 1.5 0 01-1.61 2.53A9.87 9.87 0 0024.42 21a1.5 1.5 0 01-1.59-2.54zM3.17 27a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0zm8.5-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M.34 41.3a12.83 12.83 0 0117.07-5.44 1.5 1.5 0 01-1.35 2.68A9.83 9.83 0 003 42.7a1.5 1.5 0 01-2.65-1.42zM10.17 12c0-.83.67-1.5 1.5-1.5h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5zM10.32 1.33a1.5 1.5 0 012.02-.67l4 2A1.5 1.5 0 1115 5.34l-4-2a1.5 1.5 0 01-.68-2.01zM42.17 12c0-.83.67-1.5 1.5-1.5h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5zM49 1.33a1.5 1.5 0 01-.66 2.01l-4 2A1.5 1.5 0 1143 2.66l4-2a1.5 1.5 0 012 .67zM41.17 27a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0zm8.5-5.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M58.35 42.7a9.83 9.83 0 00-13.08-4.16 1.5 1.5 0 01-1.34-2.68 12.83 12.83 0 0117.06 5.43 1.5 1.5 0 11-2.64 1.41z" clipRule="evenodd" />
</svg>;

export default Index;
