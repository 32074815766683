import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M5.25 21c0-.41.34-.75.75-.75h20a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M9.44 20.5a.75.75 0 01.56-.25h12a.75.75 0 01.75.82l-1 10L21 31v.75H11c-.39 0-.7-.3-.75-.68l-1-10a.75.75 0 01.2-.57zm10.88 9.75l.85-8.5H10.83l.85 8.5h8.64zM11.75 10.5a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zM16 7.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M11.1 16.51a6.44 6.44 0 019.8 0 .75.75 0 11-1.14.98 4.94 4.94 0 00-7.52 0 .75.75 0 01-1.15-.98z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M3 1.75c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h3a.75.75 0 010 1.5H3A2.75 2.75 0 01.25 15V3A2.75 2.75 0 013 .25h26A2.75 2.75 0 0131.75 3v12A2.75 2.75 0 0129 17.75h-3a.75.75 0 010-1.5h3c.69 0 1.25-.56 1.25-1.25V3c0-.69-.56-1.25-1.25-1.25H3z" clipRule="evenodd"/>
</svg>;

export default Index;
