import styled, { css } from 'styled-components';
import breakpoints from '../settings/breakpoints';
import { gutters, margins } from '../settings/spacing';
import { above } from '../tools/media';

export const Container = styled.div`
	position: relative;
	padding-right: ${margins[16]};
	padding-left: ${margins[16]};

	${above(breakpoints[600], () => css`
		padding-right: ${margins[24]};
		padding-left: ${margins[24]};
	`)}

	${above(breakpoints[960], () => css`
		padding-right: ${margins[48]};
		padding-left: ${margins[48]};
	`)}

	${above(breakpoints[1440], () => css`
		padding-right: ${margins[80]};
		padding-left: ${margins[80]};
	`)}

	${above(breakpoints[1920], () => css`
		max-width: 1920px;
		margin-right: auto;
		margin-left: auto;
		padding-right: ${margins[120]};
		padding-left: ${margins[120]};
	`)}
`;

export const Grid = styled(Container)`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${gutters[16]};

	${above(breakpoints[600], () => css`
		grid-template-columns: repeat(8, 1fr);
	`)}

	${above(breakpoints[960], () => css`
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: ${gutters[24]};
	`)}

	${above(breakpoints[1920], () => css`

	`)}
`;

export const InnerGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${gutters[16]};

	${above(breakpoints[600], () => css`
		grid-template-columns: repeat(8, 1fr);
	`)}

	${above(breakpoints[960], () => css`
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: ${gutters[24]};
	`)}

	${above(breakpoints[1920], () => css`
		grid-gap: ${gutters[32]};
	`)}
`;

export const GridFullStretch = styled(InnerGrid)`
	padding-left: ${margins[16]};
	padding-right: ${margins[16]};

	${above(breakpoints[600], () => css`
		padding-right: ${margins[24]};
		padding-left: ${margins[24]};
	`)}

	${above(breakpoints[960], () => css`
		padding-left: ${margins[48]};
		padding-right: ${margins[48]};
	`)}

	${above(breakpoints[1440], () => css`
		padding-left: ${margins[80]};
		padding-right: ${margins[80]};
	`)}

	${above(breakpoints[1920], () => css`
		padding-left: ${margins[120]};
		padding-right: ${margins[120]};
	`)}
`
