import styled from 'styled-components';
import { weight } from '../../../styles/settings/typography';
import { base, xs, sm, md, mdPlus, big, lg, xl, xxl, xxxl } from '../../../styles/tools/typography';

export const baseLight = styled(base)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[200]};
`;

export const baseBook = styled(base)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[300]};
`;

export const baseRegular = styled(base)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const xsBodyLight = styled(xs)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[200]};
`;

export const xsBodyBook = styled(xs)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[300]};
`;

export const xsBodyRegular = styled(xs)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const smBodyLight = styled(sm)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[200]};
`;

export const smBodyBook = styled(sm)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[300]};
`;

export const smBodyRegular = styled(sm)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const mdBodyLight = styled(md)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[200]};
`;

export const mdBodyBook = styled(md)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[300]};
`;

export const mdBodyRegular = styled(md)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const mdPlusBodyRegular = styled(mdPlus)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;
export const smTitleRegular = styled(sm)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;
export const mdTitleRegular = styled(md)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const bigTitleRegular = styled(big)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const lgTitleRegular = styled(lg)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const xlTitleRegular = styled(xl)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const xxlTitleRegular = styled(xxl)`
	font-family: synthese, sans-serif;
	font-weight: ${weight[400]};
`;

export const xxlTitleBoldOblique = styled(xxl)`
	font-family: synthese-bold-oblique, sans-serif;
	font-weight: ${weight[400]};
`;

export const xxxlTitleBoldOblique = styled(xxxl)`
	font-family: synthese-bold-oblique, sans-serif;
	font-weight: ${weight[400]};
`;

export const mdQuoteRegular = styled(md)`
	font-family: plantin, sans-serif;
	font-weight: ${weight[400]};
`;

export const lgQuoteRegular = styled(lg)`
	font-family: plantin, sans-serif;
	font-weight: ${weight[400]};
`;
