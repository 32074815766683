import React from 'react';
import colors from '../../../styles/settings/colors';

const Index = ({ fill = colors.base.blue }) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48">
		<path
			fill="none"
			stroke={fill}
			stroke-linecap="round"
			stroke-linejoin="round"
			d="M1.5 5h21a1 1 0 0 1 1 1v7.5a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5V6a1 1 0 0 1 1-1v0Z"
		/>
		<path
			fill="none"
			stroke={fill}
			stroke-linecap="round"
			stroke-linejoin="round"
			d="M1.5 14h21v1.5a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V14Z"
		/>
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M3.5 16v3" />
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M20.5 16v3" />
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M14.5 5v9" />
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M7.5 5v9" />
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M14.5 11h9" />
		<path stroke={fill} stroke-linecap="round" stroke-linejoin="round" d="M14.5 8h9" />
		<path stroke={fill} d="M5.25 7.5a.25.25 0 0 1 0-.5" />
		<path stroke={fill} d="M5.25 7.5a.25.25 0 0 0 0-.5" />
		<g>
			<path stroke={fill} d="M12.25 7.5a.25.25 0 1 1 0-.5" />
			<path stroke={fill} d="M12.25 7.5a.25.25 0 1 0 0-.5" />
		</g>
	</svg>
);

export default Index;
