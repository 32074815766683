import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M.3 22.5a4.3 4.3 0 118.5 0 4.3 4.3 0 01-8.6 0zm4.2-2.8a2.7 2.7 0 100 5.5 2.7 2.7 0 000-5.4zM23.3 22.5a4.3 4.3 0 118.5 0 4.3 4.3 0 01-8.6 0zm4.2-2.8a2.7 2.7 0 100 5.5 2.7 2.7 0 000-5.4zM11.8 22.5a4.3 4.3 0 118.5 0 4.3 4.3 0 01-8.6 0zm4.2-2.8a2.7 2.7 0 100 5.5 2.7 2.7 0 000-5.4z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M.6 27.5A6.9 6.9 0 0111 30.7a.8.8 0 11-1.5.6 5.4 5.4 0 00-8-2.6.8.8 0 01-1-1.2zM30.6 28.7a5.4 5.4 0 00-8.1 2.6.7.7 0 11-1.5-.6 6.9 6.9 0 0110.4-3.2.7.7 0 01-.8 1.2z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M9.5 30.7a6.9 6.9 0 0113 0 .8.8 0 01-1.5.6 5.4 5.4 0 00-10 0 .8.8 0 01-1.5-.6zM24.7 8.8c.1.4-.1.8-.5 1a5.7 5.7 0 00-3.5 3 10 10 0 00-1 2.3.7.7 0 01-1.4-.2l.7.1-.7-.1a3.2 3.2 0 010-.3 10.3 10.3 0 011-2.6 7.2 7.2 0 014.5-3.7c.4-.1.8.1 1 .5zM26.3 8c0-.4.3-.8.7-.8h4a.8.8 0 010 1.5h-4a.8.8 0 01-.8-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M29 5.3c.4 0 .8.3.8.7v4a.8.8 0 01-1.6 0V6c0-.4.4-.8.8-.8zM.3 8c0-.4.3-.8.7-.8h4a.8.8 0 010 1.5H1A.8.8 0 01.2 8z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M3 5.3c.4 0 .8.3.8.7v4a.8.8 0 01-1.5 0V6c0-.4.3-.8.7-.8zM13.3 3c0-.4.3-.8.7-.8h4a.8.8 0 010 1.5h-4a.8.8 0 01-.8-.7z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M16 .3c.4 0 .8.3.8.7v4a.8.8 0 01-1.6 0V1c0-.4.4-.8.8-.8zM16 8.3c.4 0 .8.3.8.7v6a.8.8 0 01-1.6 0V9c0-.4.4-.8.8-.8zM7 8.8c0 .4.2.8.6 1 1.7.4 2.8 1.6 3.5 3a10 10 0 011 2.3.7.7 0 001.4-.2l-.8.1.8-.1a3.2 3.2 0 000-.3 10.3 10.3 0 00-1-2.6 7.2 7.2 0 00-4.6-3.7.8.8 0 00-.9.5z" clipRule="evenodd" />
</svg>;

export default Index;
