import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path fill={fill} fillRule="evenodd" d="M19.58 3c0-.41.34-.75.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M22.33.25c.42 0 .75.34.75.75v4a.75.75 0 01-1.5 0V1c0-.41.34-.75.75-.75zM23.58 10c0-.41.34-.75.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M26.33 7.25c.42 0 .75.34.75.75v4a.75.75 0 01-1.5 0V8c0-.41.34-.75.75-.75zM3.28 8a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0zm6.75-5.25a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M1.97 5.43c.12-.4.54-.61.94-.49l14.7 4.7a.75.75 0 01-.46 1.42L2.45 6.37a.75.75 0 01-.48-.94zM5.04 10.4c.4.07.67.46.6.87L3.1 24.85c-.1.62.25 1.23.85 1.42m0 0l.45.14c.6.2 1.25-.1 1.51-.66l5.8-12.55a.75.75 0 111.37.63l-5.8 12.54a2.75 2.75 0 01-3.34 1.47l-.44-.14a2.75 2.75 0 01-1.87-3.12l2.53-13.59c.08-.4.47-.68.88-.6" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M4.18 26.34c.4.13.6.55.48.94L3.45 31.1a.75.75 0 01-1.43-.45l1.21-3.81c.13-.4.55-.62.95-.49zM16.35 26.87a2.88 2.88 0 115.77 0 2.88 2.88 0 01-5.77 0zm2.89-1.39a1.38 1.38 0 100 2.77 1.38 1.38 0 000-2.77zM27.2 23.18a1.38 1.38 0 100 2.77 1.38 1.38 0 000-2.77zm-2.88 1.38a2.88 2.88 0 115.76 0 2.88 2.88 0 01-5.76 0z" clipRule="evenodd" />
    <path fill={fill} fillRule="evenodd" d="M28.19 16.93a.96.96 0 00-.92-.12l-4.51 1.15a.96.96 0 00-.64.9v8a.75.75 0 11-1.5 0v-8a2.46 2.46 0 011.74-2.34l4.52-1.16a2.46 2.46 0 013.2 2.35h-.75.75v6.85a.75.75 0 11-1.5 0v-6.85c0-.31-.14-.6-.4-.78z" clipRule="evenodd" />
</svg>;

export default Index;
