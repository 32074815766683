import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
  <path fill={fill} d="M26.6667 4.00004H25.3333V1.33337H22.6667V4.00004H9.33334V1.33337H6.66667V4.00004H5.33334C3.86667 4.00004 2.66667 5.20004 2.66667 6.66671V28C2.66667 29.4667 3.86667 30.6667 5.33334 30.6667H26.6667C28.1333 30.6667 29.3333 29.4667 29.3333 28V6.66671C29.3333 5.20004 28.1333 4.00004 26.6667 4.00004ZM26.6667 28H5.33334V10.6667H26.6667V28Z" />
</svg>;

export default Index;


