import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.25 16a4.25 4.25 0 118.5 0 4.25 4.25 0 01-8.5 0zm4.25-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M8.25 3a2.75 2.75 0 015.5 0v7.25H23c1.15 0 2.17.7 2.57 1.78l5.97 15.92.04.08a2.75 2.75 0 11-5.15 1.93l-3.19-8.5-2.6 8.35a2.75 2.75 0 11-5.26-1.64l2.63-8.42h-4.26V29a2.75 2.75 0 11-5.5 0V3zM11 1.75c-.69 0-1.25.56-1.25 1.25v26a1.25 1.25 0 102.5 0V19c0-.42.34-.75.75-.75h6.03a.75.75 0 01.72.97l-2.94 9.4v.03a1.25 1.25 0 102.39.72l3.26-10.44a.75.75 0 011.42-.04l3.95 10.55a1.25 1.25 0 102.34-.88l-6-16a1.25 1.25 0 00-1.17-.81H13a.75.75 0 01-.75-.75V3c0-.7-.56-1.25-1.25-1.25z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
