import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) =>    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.24 230.24">

  <g data-name="Layer 1">
    <path fill={fill} fillRule="evenodd"
      d="M115.12 230.24a115.12 115.12 0 11115.12-115.12 115.25 115.25 0 01-115.12 115.12zm0-223A107.88 107.88 0 10223 115.12 108 108 0 00115.12 7.24z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M82.59 123a36 36 0 00-19.14 66.5l2.8-4.44a30.78 30.78 0 1119 4.56v-9.27l15.48-15.48-3.73-3.73-11.79 11.77v-24.29H80v14.56l-11.8-11.76-3.71 3.71L80 170.6v47.79h5.25v-23.54A36 36 0 0082.59 123z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M85.81 219h-6.45v-48.15l-15.73-15.72 4.57-4.57 11.16 11.16V148h6.45v23.43L97 160.29l4.56 4.56-15.75 15.73v8.36a30.14 30.14 0 10-33.37-30 30 30 0 0014.13 25.55l.51.32-3.44 5.47-.51-.33a36.65 36.65 0 1122.68 5.42zm-5.25-1.2h4v-23.5h.56a35.32 35.32 0 10-21.9-5.6l2.15-3.42A31.36 31.36 0 11113.94 159a31.53 31.53 0 01-28.68 31.2l-.65.06v-10.18l15.22-15.23L97 162l-12.39 12.37v-25.14h-4v15.41L68.2 152.27l-2.86 2.86 15.22 15.22z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M188.26 101.15a52.92 52.92 0 10-103.64 15.13l5-1.5A47.69 47.69 0 11138 148.69v-15.92l23.71-23.71-3.71-3.71-20 20V85.54h-5.25v25.15l-20-20L109 94.4l23.71 23.71v30.59a47.58 47.58 0 01-6.43-.74l-1 5.16a54.17 54.17 0 007.42.83v64.44h5.3v-27l11.47-11.47-3.71-3.71-7.76 7.71v-30a52.94 52.94 0 0050.26-52.77z"
      className="cls-1"
    />
    <path fill={fill} fillRule="evenodd"
      d="M138.57 219h-6.46v-64.48a52.52 52.52 0 01-6.92-.81l-.6-.11 1.22-6.34.59.11a45.59 45.59 0 005.71.69v-29.7l-24-24 4.57-4.56 19.39 19.39V84.94h6.46v39L158 104.49l4.57 4.57-24 24v15a46.95 46.95 0 10-48.34-33.44l.17.58-6.18 1.8-.18-.58a53.54 53.54 0 1154.53 38.06v28l7.16-7.16 4.56 4.57-11.72 11.72zm-5.25-1.2h4v-26.7l11.22-11.22-2.81-2.88-8.37 8.36v-32h.57A52.19 52.19 0 1085 115.53l3.87-1.16A48.29 48.29 0 11138 149.29h-.64v-16.77l23.46-23.46-2.82-2.86-20.6 20.6V86.14h-4v26l-20.6-20.6-2.86 2.86 23.46 23.46v31.47h-.64a48.85 48.85 0 01-5.91-.64l-.76 4a52.32 52.32 0 006.74.71h.57z"
      className="cls-1"
    />
  </g>

</svg>;

export default Index;
