import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.25 1.01733C0.25 0.60312 0.585786 0.267334 1 0.267334H15.6546C15.6546 0.267334 15.6546 0.267334 15.6546 0.267334C17.3513 0.267282 18.9858 0.906179 20.2326 2.05683L30.1658 11.2274C30.1659 11.2275 30.1658 11.2273 30.1658 11.2274C31.6559 12.602 32.151 14.7498 31.4127 16.6379C30.6744 18.5259 28.8539 19.7682 26.8267 19.7673C26.8265 19.7673 26.8268 19.7673 26.8267 19.7673H1C0.585786 19.7673 0.25 19.4315 0.25 19.0173C0.25 18.6031 0.585786 18.2673 1 18.2673H26.8267C28.2362 18.268 29.5023 17.4043 30.0157 16.0916C30.529 14.7789 30.1849 13.2856 29.1488 12.33L19.2154 3.15917C19.2153 3.15913 19.2154 3.1592 19.2154 3.15917C18.2456 2.26427 16.9743 1.76729 15.6547 1.76733H1C0.585786 1.76733 0.25 1.43155 0.25 1.01733Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M3 18.2673C3.41421 18.2673 3.75 18.6031 3.75 19.0173C3.75 20.8123 5.20507 22.2673 7 22.2673C8.79493 22.2673 10.25 20.8123 10.25 19.0173C10.25 18.6031 10.5858 18.2673 11 18.2673C11.4142 18.2673 11.75 18.6031 11.75 19.0173C11.75 21.6407 9.62335 23.7673 7 23.7673C4.37665 23.7673 2.25 21.6407 2.25 19.0173C2.25 18.6031 2.58579 18.2673 3 18.2673Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 18.2673C11.4142 18.2673 11.75 18.6031 11.75 19.0173C11.75 20.8123 13.2051 22.2673 15 22.2673C16.7949 22.2673 18.25 20.8123 18.25 19.0173C18.25 18.6031 18.5858 18.2673 19 18.2673C19.4142 18.2673 19.75 18.6031 19.75 19.0173C19.75 21.6407 17.6234 23.7673 15 23.7673C12.3766 23.7673 10.25 21.6407 10.25 19.0173C10.25 18.6031 10.5858 18.2673 11 18.2673Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4062 5.76466C13.8831 5.76469 13.4153 6.09044 13.2339 6.58107C13.0524 7.07171 13.1957 7.62344 13.5929 7.96383L13.5939 7.96468L14.8022 9.00383C14.8024 9.00398 14.8021 9.00368 14.8022 9.00383C15.7539 9.8191 16.9663 10.2676 18.2194 10.2673H26.9153L22.0395 5.76466H14.4062C14.4062 5.76466 14.4062 5.76466 14.4062 5.76466ZM11.827 6.06076C12.2262 4.98136 13.2553 4.2647 14.4062 4.26466H22.3329C22.5215 4.26466 22.7032 4.33572 22.8417 4.46367L29.3417 10.4663C29.5686 10.6758 29.6438 11.0029 29.5314 11.2905C29.4189 11.5781 29.1417 11.7673 28.8329 11.7673H18.2197C18.2196 11.7673 18.2197 11.7673 18.2197 11.7673C16.6083 11.7676 15.0498 11.1914 13.8262 10.1428L12.6168 9.10283C12.6167 9.10268 12.617 9.10298 12.6168 9.10283C11.7434 8.35393 11.4279 7.13994 11.827 6.06076Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M28.8333 11.0173C29.1803 11.6823 29.1806 11.6821 29.181 11.6819L29.1817 11.6815L29.1828 11.681L29.1839 11.6803C29.1842 11.6802 29.1837 11.6805 29.1823 11.6813L29.1636 11.6917C29.1446 11.7027 29.1128 11.7214 29.07 11.7485C28.9844 11.8028 28.8551 11.8902 28.6966 12.0142C28.3793 12.2625 27.947 12.6561 27.5119 13.2237C26.6485 14.3497 25.75 16.191 25.75 19.0173C25.75 19.4315 25.4142 19.7673 25 19.7673C24.5858 19.7673 24.25 19.4315 24.25 19.0173C24.25 15.8437 25.2681 13.6849 26.3215 12.311C26.8446 11.6286 27.3707 11.1472 27.7721 10.833C27.973 10.6758 28.1432 10.56 28.2672 10.4814C28.3293 10.4421 28.3798 10.4121 28.417 10.3908C28.4355 10.3801 28.4508 10.3716 28.4624 10.3653L28.4772 10.3573L28.4825 10.3544L28.4846 10.3533L28.4856 10.3528C28.486 10.3526 28.4864 10.3524 28.8333 11.0173Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.528 14.2673C7.55309 14.2667 10.3187 15.976 11.6709 18.6821C11.8561 19.0526 11.7058 19.5031 11.3353 19.6882C10.9647 19.8734 10.5143 19.7231 10.3291 19.3526C9.23092 17.1549 6.98491 15.7668 4.52816 15.7673H1C0.585786 15.7673 0.25 15.4315 0.25 15.0173C0.25 14.6031 0.585786 14.2673 1 14.2673H4.528C4.52795 14.2673 4.52805 14.2673 4.528 14.2673Z" fill={fill} />
</svg>;

export default Index;
