import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.monochrome.white }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M21 6h-2v9H6v2c0 .6.5 1 1 1h11l4 4V7c0-.5-.4-1-1-1zm-4 6V3c0-.5-.4-1-1-1H3a1 1 0 00-1 1v14l4-4h10c.6 0 1-.4 1-1z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
