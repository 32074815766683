import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M11.45 6c.33.25.4.72.15 1.05L8 11.85a.75.75 0 01-1.13.08l-2.4-2.4a.75.75 0 011.06-1.06l1.79 1.79 3.08-4.11A.75.75 0 0111.45 6zM11.45 17.6c.33.25.4.72.15 1.05L8 23.45a.75.75 0 01-1.13.08l-2.4-2.4a.75.75 0 011.06-1.06l1.79 1.79 3.08-4.11a.75.75 0 011.05-.15zM12.25 11c0-.42.34-.75.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75zM12.25 23c0-.42.34-.75.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M.25 3A2.75 2.75 0 013 .25h14a.75.75 0 010 1.5H3c-.69 0-1.25.56-1.25 1.25v26c0 .69.56 1.25 1.25 1.25h16a.75.75 0 110 1.5H3A2.75 2.75 0 01.25 29V3zM20.25 10c0-.41.34-.75.75-.75h6c.41 0 .75.34.75.75v15A2.75 2.75 0 0125 27.75h-2A2.75 2.75 0 0120.25 25V10zm1.5.75V25c0 .69.56 1.25 1.25 1.25h2c.69 0 1.25-.56 1.25-1.25V10.75h-4.5zM26.25 3c0-.42.34-.75.75-.75h2A2.75 2.75 0 0131.75 5v9a.75.75 0 01-1.5 0V5c0-.7-.56-1.25-1.25-1.25h-2a.75.75 0 01-.75-.75z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M20.25 3A2.75 2.75 0 0123 .25h2A2.75 2.75 0 0127.75 3v7a.75.75 0 01-1.5 0V3c0-.7-.56-1.25-1.25-1.25h-2c-.69 0-1.25.56-1.25 1.25v7a.75.75 0 01-1.5 0V3zM24 26.25c.41 0 .75.33.75.75v4a.75.75 0 01-1.5 0v-4c0-.42.34-.75.75-.75z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
