import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 80" xmlSpace="preserve">
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path
          d="M23.136 23.25l.1-1.122a3 3 0 00-2.337-3.2l-3.651-.811v-5.742A1.877 1.877 0 0015.3 10.5a1.793 1.793 0 00-1.8 1.876v9.2l-1.477-1.108a1.533 1.533 0 00-2.146 2.145l.475.633"
          transform="scale(3.33333)"
        ></path>
        <path
          d="M6.75 19.32h-4.5a1.5 1.5 0 01-1.5-1.5V2.25a1.5 1.5 0 011.5-1.5h13.5a1.5 1.5 0 011.5 1.5V7.5"
          transform="scale(3.33333)"
        ></path>
        <path
          d="M6.375 6.375a2.625 2.625 0 105.25 0 2.625 2.625 0 10-5.25 0zM11.017 10.218A4.546 4.546 0 009 9.75a4.485 4.485 0 00-4.224 3 4.534 4.534 0 00-.264 1.5M4.5 14.25h6"
          transform="scale(3.33333)"
        ></path>
      </g>
    </svg>;

export default Index;
