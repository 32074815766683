import React from 'react';
import colors from '../../../styles/settings/colors';

const Index = ({ fill = colors.base.blue }) => (
	<svg
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 80 80"
		xmlSpace="preserve"
	>
		<g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
			<path
				d="M9 12.75a5.25 5.25 0 1010.5 0 5.25 5.25 0 10-10.5 0z"
				transform="scale(3.33333)"
			></path>
			<path
				d="M19.5 6l-1.085-2.171A1.5 1.5 0 0017.073 3h-5.646a1.5 1.5 0 00-1.342.829L9 6H2.25a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h19.5a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5z"
				transform="scale(3.33333)"
			></path>
			<path
				d="M4.125 9a.375.375 0 11-.375.375A.375.375 0 014.125 9M5.25 6V4.5"
				transform="scale(3.33333)"
			></path>
		</g>
	</svg>
);

export default Index;
