import React from 'react';
import ArrowLeftIcon from '../../../svgs/UI/ArrowLeft';
import ArrowRightIcon from '../../../svgs/UI/ArrowRight';
import ArtIcon from '../../../svgs/Decorative/Art';
import BaloonDesk from '../../../svgs/Decorative/BaloonDesk';
import BeerIcon from '../../../svgs/Decorative/Beer';
import BikeIcon from '../../../svgs/Decorative/Bike';
import BuildingIcon from '../../../svgs/UI/Building';
import Building2Icon from '../../../svgs/Decorative/Building2';
import BuildingWithTreeIcon from '../../../svgs/Decorative/Building';
import BusIcon from '../../../svgs/Decorative/Bus';
import CafeIcon from '../../../svgs/Decorative/Cafe';
import Calendar from '../../../svgs/Decorative/Calendar';
import CalendarIcon2 from '../../../svgs/Decorative/Calendar2';
import ChairIcon from '../../../svgs/Decorative/Chair';
import ChatIcon from '../../../svgs/UI/Chat';
import CinemaIcon from '../../../svgs/Decorative/Cinema';
import Cocktail from '../../../svgs/Decorative/Cocktail';
import Community from '../../../svgs/Decorative/Community';
import ConciergeIcon from '../../../svgs/Decorative/Concierge';
import Construction from '../../../svgs/Decorative/Construction';
import ContractIcon from '../../../svgs/Decorative/Contract';
import CrossIcon from '../../../svgs/UI/Cross';
import DesignIcon from '../../../svgs/Decorative/Design';
import DeskIcon from '../../../svgs/Decorative/Desk';
import Desk1Icon from '../../../svgs/Decorative/Desk1';
import Desk2Icon from '../../../svgs/Decorative/Desk2';
import Desk3Icon from '../../../svgs/Decorative/Desk3';
import Desk4Icon from '../../../svgs/Decorative/Desk4';
import Desk5Icon from '../../../svgs/Decorative/Desk5';
import DownIcon from '../../../svgs/UI/Down';
import DumbellIcon from '../../../svgs/Decorative/Dumbell';
import ErrorIcon from '../../../svgs/UI/Error';
import FastTrain from '../../../svgs/Decorative/FastTrain';
import Faq from '../../../svgs/Decorative/Faq';
import Glasses from '../../../svgs/Decorative/Glasses';
import GymIcon from '../../../svgs/Decorative/Gym';
import Headset from '../../../svgs/Decorative/Headset';
import HeadphonesHumanIcon from '../../../svgs/Decorative/HeadphonesHuman';
import HealthIcon from '../../../svgs/Decorative/Health';
import HouseRetroIcon from '../../../svgs/Decorative/HouseRetro';
import JogIcon from '../../../svgs/Decorative/Jog';
import Key from '../../../svgs/Decorative/Key';
import LabIcon from '../../../svgs/Decorative/Lab';
import LeftIcon from '../../../svgs/UI/Left';
import LightIcon from '../../../svgs/Decorative/Light';
import LockIcon from '../../../svgs/Decorative/Lock';
import MeetingIcon from '../../../svgs/Decorative/Meeting';
import MoneyIcon from '../../../svgs/Decorative/Money';
import MusicIcon from '../../../svgs/Decorative/Music';
import OfferIcon from '../../../svgs/Decorative/Offer';
import ParkingIcon from '../../../svgs/Decorative/Parking';
import PhoneIcon from '../../../svgs/Decorative/Phone';
import PhoneSecondaryIcon from '../../../svgs/UI/Phone';
import PlusIcon from '../../../svgs/UI/Plus';
import PointerIcon from '../../../svgs/UI/Pointer';
import PointerIcon2 from '../../../svgs/UI/Pointer2';
import Present from '../../../svgs/Decorative/Present';
import Products from '../../../svgs/Decorative/Products';
import Skyline from '../../../svgs/Illustration/Skyline';
import SelectIcon from '../../../svgs/Decorative/Select';
import ShareIcon from '../../../svgs/Decorative/Share';
import ScienceIcon from '../../../svgs/Decorative/Science';
import ShowerIcon from '../../../svgs/Decorative/Shower';
import SpeedIcon from '../../../svgs/Decorative/Speed';
import StudentIcon from '../../../svgs/Decorative/Student';
import SubDirectoryDownIcon from '../../../svgs/UI/SubDirectoryDown';
import SubDirectoryRight from '../../../svgs/UI/SubDirectoryRight';
import SurveyIcon from '../../../svgs/Decorative/Survey';
import TalentIcon from '../../../svgs/Decorative/Talent';
import TickIcon from '../../../svgs/UI/Tick';
import TouchIcon from '../../../svgs/UI/Touch';
import Theatre from '../../../svgs/Decorative/Theatre';
import ThreeDicon from '../../../svgs/UI/3D';
import TreeIcon from '../../../svgs/Decorative/Tree';
import UpIcon from '../../../svgs/UI/Up';
import Wifi from '../../../svgs/Decorative/Wifi';
import YogaIcon from '../../../svgs/Decorative/Yoga';
import OurLocations from '../../../svgs/Illustration/OurLocations';
import OurLocationsManchester from '../../../svgs/Illustration/OurLocationsManchester';
import OurLocationsLeeds from '../../../svgs/Illustration/OurLocationsLeeds';
import OurLocationsLiverpool from '../../../svgs/Illustration/OurLocationsLiverpool';
import OurLocationsCheshire from '../../../svgs/Illustration/OurLocationsCheshire';
import OurLocationsBirmingham from '../../../svgs/Illustration/OurLocationsBirmingham';
import Montain from '../../../svgs/Decorative/Montain';
import Profile from '../../../svgs/Decorative/Profile';
import Reward from '../../../svgs/Decorative/Reward';
import Ribbon from '../../../svgs/Decorative/Ribbon';
import Camera from '../../../svgs/Decorative/Camera';
import Chatphone from '../../../svgs/Decorative/Chat';
import WindTurbine from '../../../svgs/Decorative/WindTurbine';
import Emissions from '../../../svgs/Decorative/Emissions';
import Bricks from '../../../svgs/Decorative/Bricks';
import Waterdrops from '../../../svgs/Decorative/Waterdrops';
import Recycle from '../../../svgs/Decorative/Recycle';
import CarCharging from '../../../svgs/Decorative/CarCharging';
import GreenSpaces from '../../../svgs/Decorative/GreenSpaces';
import OfficeDeclutter from '../../../svgs/Decorative/OfficeDeclutter';
import Stock from '../../../svgs/Decorative/Stock';

export const iconType = {
	threeD: ThreeDicon,
	arrowLeft: ArrowLeftIcon,
	arrowRight: ArrowRightIcon,
	art: ArtIcon,
	baloonDesk: BaloonDesk,
	beer: BeerIcon,
	bike: BikeIcon,
	building: BuildingIcon,
	building2: Building2Icon,
	buildingWithTree: BuildingWithTreeIcon,
	bus: BusIcon,
	cafe: CafeIcon,
	calendar: Calendar,
	calendar2: CalendarIcon2,
	chair: ChairIcon,
	chat: ChatIcon,
	cinema: CinemaIcon,
	cocktail: Cocktail,
	community: Community,
	concierge: ConciergeIcon,
	construction: Construction,
	contract: ContractIcon,
	cross: CrossIcon,
	design: DesignIcon,
	desk: DeskIcon,
	desk1: Desk1Icon,
	desk2: Desk2Icon,
	desk3: Desk3Icon,
	desk4: Desk4Icon,
	desk5: Desk5Icon,
	down: DownIcon,
	dumbell: DumbellIcon,
	error: ErrorIcon,
	fastTrain: FastTrain,
	faq: Faq,
	glasses: Glasses,
	gym: GymIcon,
	headset: Headset,
	headphonesHuman: HeadphonesHumanIcon,
	health: HealthIcon,
	houseRetro: HouseRetroIcon,
	jog: JogIcon,
	key: Key,
	lab: LabIcon,
	left: LeftIcon,
	light: LightIcon,
	lock: LockIcon,
	meeting: MeetingIcon,
	money: MoneyIcon,
	music: MusicIcon,
	offer: OfferIcon,
	parking: ParkingIcon,
	phone: PhoneIcon,
	phoneSecondary: PhoneSecondaryIcon,
	plus: PlusIcon,
	pointer: PointerIcon,
	pointer2: PointerIcon2,
	present: Present,
	products: Products,
	skyline: Skyline,
	share: ShareIcon,
	science: ScienceIcon,
	select: SelectIcon,
	shower: ShowerIcon,
	speed: SpeedIcon,
	student: StudentIcon,
	subDirectoryDown: SubDirectoryDownIcon,
	subDirectoryRight: SubDirectoryRight,
	survey: SurveyIcon,
	talent: TalentIcon,
	theatre: Theatre,
	tick: TickIcon,
	touch: TouchIcon,
	tree: TreeIcon,
	up: UpIcon,
	wifi: Wifi,
	yoga: YogaIcon,
	ourLocations: OurLocations,
	ourLocationsManchester: OurLocationsManchester,
	ourLocationsLiverpool: OurLocationsLiverpool,
	ourLocationsLeeds: OurLocationsLeeds,
	ourLocationsCheshire: OurLocationsCheshire,
	ourLocationsBirmingham: OurLocationsBirmingham,
	montain: Montain,
	profile: Profile,
	reward: Reward,
	ribbon: Ribbon,
	camera: Camera,
	chatphone: Chatphone,
	windTurbine: WindTurbine,
	emissions: Emissions,
	bricks: Bricks,
	waterdrops: Waterdrops,
	recycle: Recycle,
	carcharging: CarCharging,
	greenspaces: GreenSpaces,
	officeDeclutter: OfficeDeclutter,
	stock: Stock,
};

const SVG = ({ name, ...props }) => {
	const Icon = iconType[name];

	return Icon ? <Icon {...props} /> : null;
};

export default SVG;
