import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <defs />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M11.5.5c.3.3.3.7 0 1-.3.4-.3.6-.3.7l.4.9c.3.4.6.9.7 1.4.1.7-.1 1.4-.8 2a.7.7 0 11-1-1c.3-.4.3-.6.3-.7l-.4-.9c-.3-.4-.6-.9-.7-1.4-.1-.7.1-1.4.8-2 .3-.3.7-.3 1 0zM16.5.5c.3.3.3.7 0 1-.3.4-.3.6-.3.7l.4.9c.3.4.6.9.7 1.4.1.7-.1 1.4-.8 2a.7.7 0 11-1-1c.3-.4.3-.6.3-.7l-.4-.9c-.3-.4-.6-.9-.7-1.4-.1-.7.1-1.4.8-2 .3-.3.7-.3 1 0zM21.5.5c.3.3.3.7 0 1-.3.4-.3.6-.3.7l.4.9c.3.4.6.9.7 1.4.1.7-.1 1.4-.8 2a.7.7 0 11-1-1c.3-.4.3-.6.3-.7l-.4-.9c-.3-.4-.6-.9-.7-1.4-.1-.7.1-1.4.8-2 .3-.3.7-.3 1 0zM8.3 21.8c0 .4-.2.8-.6 1-1.6.4-2.9 1-3.8 1.6-.8.6-1.1 1.1-1.1 1.6 0 .4.2.9.8 1.4.6.5 1.5 1 2.8 1.4 2.4.9 5.8 1.4 9.6 1.4s7.2-.5 9.6-1.4c1.3-.4 2.2-1 2.8-1.4.6-.5.9-1 .9-1.4 0-.5-.4-1-1.2-1.6-1-.7-2.2-1.2-3.8-1.7a.7.7 0 01.3-1.4c1.8.5 3.3 1 4.3 1.8s1.9 1.7 1.9 2.9c0 1-.6 1.8-1.4 2.5-.8.7-2 1.3-3.2 1.7-2.7 1-6.3 1.5-10.2 1.5-4 0-7.5-.5-10.2-1.5-1.3-.4-2.4-1-3.2-1.7-.8-.7-1.4-1.5-1.4-2.5 0-1.2.8-2.1 1.9-2.9a14 14 0 014.3-1.8c.4-.1.8.1.9.5z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        d="M16 27.5c-4 0-7.2-1.9-8-3.4-.4-.5-2.6-3.8-1.7-10.5 0-.4.5-.6.9-.6.4.1.7.4.6.8-1 6.6 1.5 9.5 1.5 9.6.3.7 2.8 2.6 6.8 2.6s6.5-1.9 6.7-2.5c0-.1 2.5-3.1 1.5-9.6-.1-.4.2-.8.6-.8.4-.1.8.2.8.6 1 6.7-1.2 9.9-1.7 10.5-.6 1.4-4 3.3-8 3.3z"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M9.3 10.5c1.8-.8 4.1-1.3 6.7-1.3 2.6 0 5 .5 6.7 1.3.8.4 1.6.9 2.1 1.4a3 3 0 011 2.1 3 3 0 01-1 2c-.5.6-1.3 1.1-2.1 1.5-1.8.8-4.1 1.3-6.7 1.3-2.6 0-5-.5-6.7-1.3-.8-.4-1.6-.9-2.1-1.4a3 3 0 01-1-2.1 3 3 0 011-2c.5-.6 1.3-1.1 2.1-1.5zm-1 2.5c-.4.3-.6.7-.6 1 0 .3.2.7.6 1 .3.4.9.8 1.6 1.1 1.6.7 3.7 1.1 6.1 1.1 2.4 0 4.5-.4 6-1 .8-.4 1.4-.8 1.7-1.2.4-.3.6-.7.6-1 0-.3-.2-.7-.6-1-.3-.4-.9-.8-1.6-1.1-1.6-.7-3.7-1.2-6.1-1.2-2.4 0-4.5.5-6 1.2-.8.3-1.4.7-1.7 1z"
        clipRule="evenodd"
    />
    <path
        fill={fill}
        fillRule="evenodd"
        d="M10.5 16.6l-.6.6a.7.7 0 11-1.1-1l.7-.7a9.5 9.5 0 016.5-2.3 9.5 9.5 0 016.5 2.3l.7.6a.8.8 0 01-1 1.1l-.7-.6a8 8 0 00-5.5-1.9c-2 0-4 .6-5.5 1.9z"
        clipRule="evenodd"
    />
</svg>;

export default Index;
