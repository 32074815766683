import { clientConfig } from '@/env/config';

declare global {
	interface Window {
		dataLayer: any;
	}
}

const config = clientConfig();

export const pushEvent = (event: any) => {
	const {
		gaCategory: eventCategory,
		gaAction: eventAction,
		gaLabel: eventLabel,
	} = event.target.dataset;

	if (eventAction === undefined || eventCategory === undefined) {
		return;
	}

	const dataLayerEvent = {
		event: 'analyticsEvent',
		eventCategory,
		eventAction,
		eventLabel,
		nonInteraction: false,
	};

	if (typeof window !== 'undefined') {
		window.dataLayer.push(dataLayerEvent);
	}
};

export const pushSessionIdToDataLayer = () => {
	if (typeof window !== 'undefined') {
		const uniqueSessionIdEvent = {
			event: 'pageview',
			uniquePageId: window.crypto.randomUUID(),
		};

		if (!window.dataLayer?.some(obj => obj.event === 'pageview')) {
			window.dataLayer?.push(uniqueSessionIdEvent);
		}
	}
};

export const gtmScript = `<!-- Google Tag Manager -->
	(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	})(window,document,'script','dataLayer','${config.gtag}')
	<!-- End Google Tag Manager -->`;

export const gtmNoScript = `<!-- Google Tag Manager (noscript) -->
	<iframe title="Google Tag Manager Script" src="https://www.googletagmanager.com/ns.html?id=${config.gtag}"
	height="0" width="0" style="display:none;visibility:hidden"></iframe>
	<!-- End Google Tag Manager (noscript) -->`;
