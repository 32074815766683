import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M11.25 25a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zM16 21.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM8.4 17.4c4.2-4.2 11-4.2 15.2 0a.75.75 0 11-1.06 1.06 9.25 9.25 0 00-13.08 0A.75.75 0 118.4 17.4z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M4.16 13.16a16.75 16.75 0 0123.68 0 .75.75 0 01-1.06 1.06 15.25 15.25 0 00-21.56 0 .75.75 0 11-1.06-1.06z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M.49 8.36a22.75 22.75 0 0131.02 0 .75.75 0 11-1.02 1.1 21.25 21.25 0 00-28.98 0A.75.75 0 01.5 8.36z" clipRule="evenodd"/>
</svg>;

export default Index;
