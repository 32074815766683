import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Browser from '../../../lib/browser';
import SVG from '../SVG';
import { StyledLinkCardsBlock, StyledLinkStandard, StyledLinkStandardTitle } from './index.styled';

const Link = props => {
	const {
		url,
		dataGAAction,
		dataGACategory,
		dataGALabel,
		isTitle,
		isAnchorLink,
		title,
		linkIcon,
		callBack,
		showIcon,
		isCardsBlock,
	} = props;
	const theme = useContext(ThemeContext) || {};
	let LinkStyled = isTitle ? StyledLinkStandardTitle : StyledLinkStandard;
	if (isCardsBlock) LinkStyled = StyledLinkCardsBlock;

	// eslint-disable-next-line no-unused-expressions
	isTitle ? StyledLinkStandardTitle : StyledLinkStandard;

	return (
		<LinkStyled
			as="a"
			href={url}
			data-ga-action={dataGAAction}
			data-ga-category={dataGACategory}
			data-ga-label={dataGALabel}
			target={isAnchorLink ? '_blank' : undefined}
			onClick={event => Browser.handleClick(event, url, callBack)}
		>
			{title}

			{showIcon && <SVG name={linkIcon} fill={theme.primaryForeground} aria-hidden="true" />}
		</LinkStyled>
	);
};

export default Link;
