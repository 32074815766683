import pxToRem from '../tools/pxToRem';

type FontTypes = {
	[key: number]: string,
}

export const size: FontTypes = {
	12: pxToRem(12),
	14: pxToRem(14),
	16: pxToRem(16),
	18: pxToRem(18),
	20: pxToRem(20),
	24: pxToRem(24),
	32: pxToRem(32),
	36: pxToRem(36),
	40: pxToRem(40),
	48: pxToRem(48),
	64: pxToRem(64)
};

export const lineHeight: FontTypes = {
	20: '20px',
	24: '24px',
	28: '28px',
	32: '32px',
	36: '36px',
	40: '40px',
	48: '48px',
	56: '56px',
	64: '64px',
	72: '72px'
};

export const weight = {
	200: 200,
	300: 300,
	400: 400
};
