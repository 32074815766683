import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above, hover } from '../../../styles/tools/media';
import { smBodyRegular } from '../Typography/index.styled';

export const StyledLinkStandard = styled(smBodyRegular)`
	position: relative;
	display: inline-flex;
	align-items: center;
	width: fit-content;
	margin-bottom: ${gap[0]};
	padding-bottom: ${gap[4]};
	border-bottom: 2px solid var(--primaryAccent);
	color: var(--primaryForeground);
	text-decoration: none;
	transition: background-color, color 150ms ease-in 125ms;
	cursor: pointer;

	&:focus {
		& > svg > path {
			fill: var(--primaryAccent);
			transform: translateX(8px);
		}

		&:before {
			transform: scaleX(1);
		}
	}

	&:before {
		content: '';
		position: absolute;
		right: 0px;
		bottom: -2px;
		left: 0px;
		display: block;
		height: 2px;
		background-color: var(--primaryForeground);
		transform: scaleX(0);
		transform-origin: 0% 50% 0px;
		transition: transform 250ms cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
	}

	& > svg {
		height: 24px;
		width: 24px;
		min-width: 24px;
		display: block;
		margin-left: ${gap[8]};

		& > path {
			fill: var(--primaryForeground);
			transition: all 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
		}

		${above(breakpoints[600], () => css`
			min-width: 28px;
			width: 28px;
			height: 28px;
		`)}
	}

	${hover(() => css`
		&:hover {
			& > svg > path {
				fill: var(--primaryAccent);
				transform: translateX(8px);
			}

			&:before {
				transform: scaleX(1);
			}
		}
	`)}
`;

export const StyledLinkStandardTitle = styled(StyledLinkStandard)`
	margin-bottom: ${gap[16]};

	${above(breakpoints[600], () => css`
		margin-bottom: ${gap[24]};
	`)}
`;


export const StyledLinkCardsBlock = styled(StyledLinkStandard)`
		grid-row: 4;
`;
