import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
<path fill={fill} fillRule="evenodd" d="M3.238 2.5A2.25 2.25 0 015.488.25h13a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-13a2.25 2.25 0 01-2.25-2.25v-9zm2.25-.75a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h13a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75h-13zM2.606 17.75a.25.25 0 00-.223.362l1 2c.042.084.129.138.224.138H28.37a.251.251 0 00.224-.139l1-2M2.606 17.75zm-1.488-.67a1.75 1.75 0 011.488-.83H29.37a1.75 1.75 0 011.566 2.532l-1 2a1.752 1.752 0 01-1.565.968H3.606a1.752 1.752 0 01-1.565-.968l-1-2a1.75 1.75 0 01.077-1.703z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M7.204 20.282a.75.75 0 01.503.933l-2.71 9.035h.567l5.781-9.636a.75.75 0 111.286.772l-6 10a.75.75 0 01-.643.364h-2a.75.75 0 01-.718-.965l3-10a.75.75 0 01.934-.503zM24.772 20.282a.75.75 0 01.934.503l3 10a.75.75 0 01-.719.965h-2a.75.75 0 01-.643-.364l-6-10a.75.75 0 011.287-.772l5.781 9.636h.567l-2.71-9.035a.75.75 0 01.503-.933zM3.238 9a.75.75 0 01.75-.75h16a.75.75 0 010 1.5h-16a.75.75 0 01-.75-.75zM10.17 12.272a.75.75 0 01.545.91l-1 4a.75.75 0 01-1.455-.364l1-4a.75.75 0 01.91-.546zM13.806 12.272a.75.75 0 01.91.546l1 4a.75.75 0 01-1.456.364l-1-4a.75.75 0 01.546-.91zM23.238 13a.75.75 0 01.75-.75h4a.75.75 0 01.75.75v4a.75.75 0 01-.75.75h-4a.75.75 0 01-.75-.75v-4zm1.5.75v2.5h2.5v-2.5h-2.5z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M28.266 7.304a.75.75 0 01.418.975l-2 5a.75.75 0 01-1.393-.557l2-5a.75.75 0 01.975-.418z" clipRule="evenodd"/>
</svg>;

export default Index;
