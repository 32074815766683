import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
<defs/>
<path fill={fill} fillRule="evenodd" d="M3 8.75c-.69 0-1.25.56-1.25 1.25v19c0 .69.56 1.25 1.25 1.25h26c.69 0 1.25-.56 1.25-1.25V13a.75.75 0 011.5 0v16A2.75 2.75 0 0129 31.75H3A2.75 2.75 0 01.25 29V10A2.75 2.75 0 013 7.25h12a.75.75 0 010 1.5H3z" clipRule="evenodd"/>
<path fill={fill} fillRule="evenodd" d="M28.94 2.16c-.91-.68-2.2-.5-2.9.41L17.5 13.81l-.22 3.74 3.54-1.24 8.54-11.23c.7-.91.51-2.21-.4-2.9l-.02-.01zm-4.09-.5a3.57 3.57 0 015-.69l.02.02a3.57 3.57 0 01.69 5L21.88 17.4a.75.75 0 01-.35.25l-4.82 1.68a.75.75 0 01-1-.75l.3-5.09c.02-.15.07-.3.16-.4l8.68-11.42z" clipRule="evenodd"/>
<path fill={fill} d="M16.2 28.5c-2.4 0-4.5-1.3-5.6-3.5-1.1-2.3-.8-5 1-7.2.3-.3.7-.4 1.1-.1.4.3.4.7.1 1.1-1.4 1.7-1.7 3.8-.8 5.5.8 1.7 2.4 2.7 4.2 2.7 1.5 0 2.9-.7 3.8-1.9.9-1.2 1.2-2.7.8-4.1-.1-.4.1-.8.5-.9.4-.1.8.1.9.5.5 1.9.1 3.9-1.1 5.4a5.84 5.84 0 01-4.9 2.5z"/>
<path fill={fill} d="M10.8 22.5H8c-.5 0-1.1-.2-1.4-.6-.4-.4-.6-.9-.6-1.4V14c0-1.1.9-2 2-2h3.7c.4 0 .8.3.8.8s-.3.7-.7.7H8c-.3 0-.5.2-.5.5v6.4c0 .1.1.3.2.4.1.1.2.2.3.2h2.7c.4 0 .8.3.8.8s-.3.7-.7.7z"/>
</svg>;

export default Index;

