import React from 'react';

import colors from '../../../styles/settings/colors'

const Montain = ({ fill = colors.base.blue }) => (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M35.227 26.8111L14.0507 60.444H59.2833L38.107 26.8111C37.7539 26.3806 37.2258 26.1299 36.667 26.1299C36.1082 26.1299 35.58 26.3806 35.227 26.8111ZM32.8108 25.0302C33.7312 23.8321 35.1562 23.1299 36.667 23.1299C38.1778 23.1299 39.6028 23.8321 40.5232 25.0302C40.5515 25.0671 40.5782 25.1054 40.603 25.1448L63.2697 61.1448C63.5607 61.607 63.5775 62.1908 63.3135 62.669C63.0495 63.1471 62.5465 63.444 62.0003 63.444H11.3336C10.7875 63.444 10.2845 63.1471 10.0205 62.669C9.7565 62.1908 9.77329 61.607 10.0643 61.1448L32.731 25.1448C32.7558 25.1054 32.7824 25.0671 32.8108 25.0302Z" />
        <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M11.8978 42.4475C12.4049 42.4129 12.895 42.6374 13.2 43.044L19 50.7773C19.4971 51.4401 19.3627 52.3803 18.7 52.8773C18.0373 53.3744 17.0971 53.2401 16.6 52.5773L12.1879 46.6945L4.54927 60.444H11.3333C12.1618 60.444 12.8333 61.1156 12.8333 61.944C12.8333 62.7724 12.1618 63.444 11.3333 63.444H2C1.46852 63.444 0.976722 63.1628 0.707194 62.7047C0.437666 62.2466 0.430655 61.6801 0.688764 61.2155L10.6888 43.2155C10.9356 42.7712 11.3907 42.4821 11.8978 42.4475Z" />
        <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M22.0871 42.0779C22.6243 41.4473 23.5711 41.3716 24.2017 41.9088L30.4315 47.2163C30.8945 47.6069 31.5796 47.5782 32.0083 47.15C32.0083 47.1499 32.0082 47.1501 32.0083 47.15L33.7203 45.438C35.3473 43.8114 37.985 43.8112 39.6121 45.4378L41.324 47.1497C41.3241 47.1498 41.3239 47.1497 41.324 47.1497C41.7531 47.5784 42.4395 47.607 42.9025 47.2151L42.9098 47.2089L49.1845 41.9662C49.8202 41.4351 50.7662 41.5198 51.2973 42.1556C51.8285 42.7913 51.7437 43.7372 51.108 44.2684L44.8367 49.5083C43.1828 50.9047 40.7346 50.8022 39.2032 49.2716L37.4911 47.5595C37.491 47.5594 37.4911 47.5595 37.4911 47.5595C37.0355 47.1042 36.2969 47.1041 35.8414 47.5595L34.1296 49.2713C32.597 50.8032 30.1458 50.9049 28.4918 49.5049L28.4881 49.5018L22.2561 44.1925C21.6255 43.6552 21.5499 42.7085 22.0871 42.0779Z" />
        <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M36 0.444C36.8284 0.444 37.5 1.11557 37.5 1.944V24.712C37.5 25.5404 36.8284 26.212 36 26.212C35.1716 26.212 34.5 25.5404 34.5 24.712V1.944C34.5 1.11557 35.1716 0.444 36 0.444Z" />
        <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M34.5 1.944C34.5 1.11557 35.1716 0.444 36 0.444H46C46.8284 0.444 47.5 1.11557 47.5 1.944V4.444H58C58.5532 4.444 59.0615 4.74848 59.3225 5.23621C59.5835 5.72395 59.5549 6.31577 59.2481 6.77605L55.8028 11.944L59.2481 17.1119C59.5549 17.5722 59.5835 18.1641 59.3225 18.6518C59.0615 19.1395 58.5532 19.444 58 19.444H46C45.1716 19.444 44.5 18.7724 44.5 17.944V17.444H36C35.1716 17.444 34.5 16.7724 34.5 15.944C34.5 15.1156 35.1716 14.444 36 14.444H46C46.8284 14.444 47.5 15.1156 47.5 15.944V16.444H55.1972L52.7519 12.7761C52.416 12.2722 52.416 11.6158 52.7519 11.1119L55.1972 7.444H46C45.1716 7.444 44.5 6.77243 44.5 5.944V3.444H36C35.1716 3.444 34.5 2.77243 34.5 1.944Z" />
    </svg>

);

export default Montain;
