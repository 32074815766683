import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) =>   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.24 230.24">
<path fill={fill} fillRule="evenodd"
  d="M75.91 148.54v-16.9c-.61 0-1.18-.11-1.74-.11H51.25c-3.39 0-4-.65-4-4.09v-19.66c0-3.28.66-3.92 4-3.92h24.61v-17.1H50.34c-2.29 0-3.1-.79-3.11-3.11V62.36c0-2.23.93-3.15 3.14-3.15h113.85c2.26 0 3.18.95 3.18 3.27V81.42c.65 0 1.21.09 1.77.09h23.07c3.18 0 3.88.72 3.88 3.93v20.25c0 2.66-.8 3.46-3.42 3.46h-25.2v16.93c.55 0 1.12.08 1.68.08h23.07c3.17 0 3.87.71 3.87 3.92v19.82c0 3.22-.69 3.93-3.86 3.93H167.4v18.68c0 2.85-.81 3.65-3.71 3.66H50.28c-2.15-.1-3-1-3.05-3.13v-13.75-7.39c0-2.46.94-3.36 3.42-3.36h25.26zm28.66 22.32v-17h-52v17zm-23.2-84v16.93h51.93V86.84zm109.33 17V86.87h-51.91v16.92zM162 154h-51.88v16.89H162zM52.64 64.57v16.82h52V64.57zm-.07 61.48h52v-16.79h-52zM162 81.44V64.62h-51.9v16.82zm-52 44.61h52v-16.77h-52zm23.29 5.59h-52v16.78h52zm5.45 0v16.88h51.94v-16.93z"
  className="cls-1"
/>
<path fill={fill} fillRule="evenodd"
  d="M115.12 230.24a115.12 115.12 0 11115.12-115.12 115.25 115.25 0 01-115.12 115.12zm0-223A107.88 107.88 0 10223 115.12 108 108 0 00115.12 7.24z"
  className="cls-1"
/>
</svg>;

export default Index;
