const base = 8;

type SpacingTypes = {
	[key: number]: string,
};

// Use for typography and iconography spacing
export const gap = {
	0: '0px',
	4: `${base / 2}px`,
	8: `${base}px`,
	12: `${base * 1.5}px`,
	16: `${base * 2}px`,
	20: `${base * 2.5}px`,
	24: `${base * 3}px`,
	28: `${base * 3.5}px`,
	32: `${base * 4}px`,
	36: `${base * 4.5}px`,
	40: `${base * 5}px`,
	44: `${base * 5.5}px`,
	48: `${base * 6}px`,
	52: `${base * 6.5}px`,
	56: `${base * 7}px`,
	60: `${base * 7.5}px`,
	64: `${base * 8}px`,
	80:  `${base * 10}px`
};

// Use for spacing between components
export const spacing: SpacingTypes = {
	48: `${base * 6}px`,
	64: `${base * 8}px`,
	80: `${base * 10}px`
};

// Use for container spacing
export const margins: SpacingTypes = {
	16: `${base * 2}px`,
	20: `${base * 2.5}px`,
	24: `${base * 3}px`,
	32: `${base * 4}px`,
	48: `${base * 6}px`,
	56: `${base * 7}px`,
	64: `${base * 8}px`,
	80: `${base * 10}px`,
	120: `${base * 15}px`
};

// Use for gap between grid items
export const gutters: SpacingTypes = {
	16: `${base * 2}px`,
	24: `${base * 3}px`,
	32: `${base * 4}px`
};
