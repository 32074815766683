import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg viewBox="0 0 64 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M58 28.5C58.8284 28.5 59.5 29.1716 59.5 30V58C59.5 58.8284 58.8284 59.5 58 59.5H46C45.1716 59.5 44.5 58.8284 44.5 58C44.5 57.1716 45.1716 56.5 46 56.5H56.5V30C56.5 29.1716 57.1716 28.5 58 28.5Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M6 28.5C6.82843 28.5 7.5 29.1716 7.5 30V56.5H18C18.8284 56.5 19.5 57.1716 19.5 58C19.5 58.8284 18.8284 59.5 18 59.5H6C5.17157 59.5 4.5 58.8284 4.5 58V30C4.5 29.1716 5.17157 28.5 6 28.5Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M8.5 2C8.5 1.17157 9.17157 0.5 10 0.5H18C18.8284 0.5 19.5 1.17157 19.5 2V10C19.5 10.8284 18.8284 11.5 18 11.5H10C9.17157 11.5 8.5 10.8284 8.5 10V2ZM11.5 3.5V8.5H16.5V3.5H11.5Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M6.5 2C6.5 1.17157 7.17157 0.5 8 0.5H20C20.8284 0.5 21.5 1.17157 21.5 2C21.5 2.82843 20.8284 3.5 20 3.5H8C7.17157 3.5 6.5 2.82843 6.5 2Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M44.5 2C44.5 1.17157 45.1716 0.5 46 0.5H54C54.8284 0.5 55.5 1.17157 55.5 2V10C55.5 10.8284 54.8284 11.5 54 11.5H46C45.1716 11.5 44.5 10.8284 44.5 10V2ZM47.5 3.5V8.5H52.5V3.5H47.5Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M42.5 2C42.5 1.17157 43.1716 0.5 44 0.5H56C56.8284 0.5 57.5 1.17157 57.5 2C57.5 2.82843 56.8284 3.5 56 3.5H44C43.1716 3.5 42.5 2.82843 42.5 2Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M38.0018 10C38.0018 9.17157 38.6734 8.5 39.5018 8.5H54.7204C54.7204 8.5 54.7204 8.5 54.7204 8.5C57.3424 8.49988 59.6 10.3507 60.114 12.9218C60.114 12.9219 60.114 12.9217 60.114 12.9218L63.4714 29.7058C63.5595 30.1465 63.4454 30.6034 63.1605 30.951C62.8756 31.2985 62.4499 31.5 62.0005 31.5H54.0005C53.1721 31.5 52.5005 30.8284 52.5005 30C52.5005 29.1716 53.1721 28.5 54.0005 28.5H60.1707L57.1723 13.5102C56.9387 12.3415 55.9125 11.4999 54.7206 11.5H39.5018C38.6734 11.5 38.0018 10.8284 38.0018 10Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M9.27992 11.5C8.08805 11.4999 7.06186 12.3412 6.82825 13.51L3.82978 28.5H10C10.8284 28.5 11.5 29.1716 11.5 30C11.5 30.8284 10.8284 31.5 10 31.5H2.00001C1.55062 31.5 1.12489 31.2985 0.839985 30.951C0.555077 30.6034 0.441003 30.1465 0.529151 29.7058L3.88644 12.922C3.88645 12.9219 3.88643 12.9221 3.88644 12.922C4.40045 10.3509 6.65801 8.49988 9.28001 8.5C9.28004 8.5 9.27998 8.5 9.28001 8.5H24.4987C25.3271 8.5 25.9987 9.17157 25.9987 10C25.9987 10.8284 25.3271 11.5 24.4987 11.5H9.27992Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M18 17.1666C18.8284 17.1666 19.5 17.8382 19.5 18.6666V56.5H44.5V18.6666C44.5 17.8382 45.1716 17.1666 46 17.1666C46.8284 17.1666 47.5 17.8382 47.5 18.6666V58C47.5 58.8284 46.8284 59.5 46 59.5H18C17.1716 59.5 16.5 58.8284 16.5 58V18.6666C16.5 17.8382 17.1716 17.1666 18 17.1666Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M27.6001 3.36663C28.6388 1.98169 30.2689 1.16663 32.0001 1.16663C33.7313 1.16663 35.3614 1.98169 36.4001 3.36663L51.2001 23.1C51.6971 23.7627 51.5628 24.7029 50.9001 25.2C50.2373 25.697 49.2971 25.5627 48.8001 24.9L34.0001 5.16663C33.528 4.53711 32.787 4.16663 32.0001 4.16663C31.2132 4.16663 30.4722 4.53711 30.0001 5.16663L15.2001 24.9C14.703 25.5627 13.7628 25.697 13.1001 25.2C12.4373 24.7029 12.303 23.7627 12.8001 23.1L27.6001 3.36663Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M30 39.5C28.6193 39.5 27.5 40.6193 27.5 42V56.5H36.5V42C36.5 40.6193 35.3807 39.5 34 39.5H30ZM24.5 42C24.5 38.9624 26.9624 36.5 30 36.5H34C37.0376 36.5 39.5 38.9624 39.5 42V58C39.5 58.8284 38.8284 59.5 38 59.5H26C25.1716 59.5 24.5 58.8284 24.5 58V42Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M0.5 58C0.5 57.1716 1.17157 56.5 2 56.5H62C62.8284 56.5 63.5 57.1716 63.5 58C63.5 58.8284 62.8284 59.5 62 59.5H2C1.17157 59.5 0.5 58.8284 0.5 58Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M30.5 23C30.5 22.1716 31.1716 21.5 32 21.5C33.3807 21.5 34.5 22.6193 34.5 24C34.5 24.8284 33.8284 25.5 33 25.5C32.3231 25.5 31.7509 25.0517 31.5643 24.4357C30.9483 24.2491 30.5 23.6769 30.5 23Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M32.4357 24.4357C33.0517 24.2491 33.5 23.6769 33.5 23C33.5 22.1716 32.8284 21.5 32 21.5C30.6193 21.5 29.5 22.6193 29.5 24C29.5 24.8284 30.1716 25.5 31 25.5C31.6769 25.5 32.2491 25.0517 32.4357 24.4357Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M32.4357 23.5643C32.2491 22.9483 31.6769 22.5 31 22.5C30.1716 22.5 29.5 23.1716 29.5 24C29.5 25.3807 30.6193 26.5 32 26.5C32.8284 26.5 33.5 25.8284 33.5 25C33.5 24.3231 33.0517 23.7509 32.4357 23.5643Z" />
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M33 22.5C33.8284 22.5 34.5 23.1716 34.5 24C34.5 25.3807 33.3807 26.5 32 26.5C31.1716 26.5 30.5 25.8284 30.5 25C30.5 24.3231 30.9483 23.7509 31.5643 23.5643C31.7509 22.9483 32.3231 22.5 33 22.5Z" />
</svg>;

export default Index;
