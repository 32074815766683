import React from 'react'
import colors from '../../../styles/settings/colors'

const Index = ({ fill = colors.base.blue }) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
  <defs/>
  <path fill={fill} fillRule="evenodd" d="M10.25 29.76c0-.41.34-.75.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M15 17.49c.42 0 .75.34.75.75v11.52a.75.75 0 01-1.5 0V18.24c0-.41.34-.75.75-.75z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M5.42 5.97h19.17c.69 0 1.33.4 1.61 1.05s.12 1.4-.39 1.88l-10.29 9.88a.75.75 0 01-1.04 0L4.2 8.9a1.68 1.68 0 01-.39-1.88c.28-.65.93-1.05 1.62-1.05zm0 1.5c-.12 0-.2.07-.24.14a.18.18 0 00.05.2L15 17.2l9.78-9.38a.18.18 0 00.04-.21.25.25 0 00-.23-.14H5.42z" clipRule="evenodd"/>
  <path fill={fill} fillRule="evenodd" d="M5.89 2.37c-.2-.4-.63-.66-1.12-.66H1a.75.75 0 010-1.5h3.77c1.02 0 1.98.56 2.45 1.46l2.45 4.7a.75.75 0 11-1.33.7l-2.45-4.7zM29.65 3.7a4.34 4.34 0 00-4.78-1.83 4.08 4.08 0 00-3.03 4.7.75.75 0 11-1.47.3A5.58 5.58 0 0124.49.42a5.84 5.84 0 016.44 2.5 5.4 5.4 0 01-.8 6.7 5.9 5.9 0 01-6.85 1.04.75.75 0 01.69-1.33 4.4 4.4 0 005.1-.76 3.9 3.9 0 00.58-4.85z" clipRule="evenodd"/>
</svg>;

export default Index;
